import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import BlogsComponent from "../front/pages/blogs";
import HomeComponent from "../front/pages/home";
import FrontLayout from "../front/layouts";
import JobsComponent from "../front/pages/jobs";
import AboutComponent from "../front/pages/about";
import TermsServiceComponent from "../front/pages/terms-service";
import PrivacyPolicyComponent from "../front/pages/privacy-policy";
import PostJobComponent from "../front/pages/post_a_job";
// import BlogDetailsComponent from "../front/pages/blogs/blog_details";
import BlogDetails_1Component from "../front/pages/blogs/blog_details_1";
import BlogDetails_2Component from "../front/pages/blogs/blog_details_2";
import BlogDetails_3Component from "../front/pages/blogs/blog_details_3";
import { ToastContainer } from "react-toastify";
import DatabaseComponent from "../front/pages/database";
import ComingSoonComponent from "../front/components/coming_soon";

const Root = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<FrontLayout />}>
          <Route index element={<HomeComponent />} />
          <Route exact path="post-a-job" element={<PostJobComponent />} />
          <Route exact path="jobs" element={<JobsComponent />} />
          <Route exact path="blogs" element={<BlogsComponent />} />
          <Route exact path="coming-soon" element={<ComingSoonComponent />} />
          {/* <Route exact path="blog-details" element={<BlogDetailsComponent />} /> */}
          <Route
            exact
            path="blog-details-1/:id"
            element={<BlogDetails_1Component />}
          />
          <Route
            exact
            path="blog-details-2"
            element={<BlogDetails_2Component />}
          />
          <Route
            exact
            path="blog-details-3"
            element={<BlogDetails_3Component />}
          />
          <Route exact path="about-us" element={<AboutComponent />} />
          <Route
            exact
            path="terms-and-service"
            element={<TermsServiceComponent />}
          />
          <Route
            exact
            path="privacy-policy"
            element={<PrivacyPolicyComponent />}
          />

          <Route
            exact
            path="database"
            element={
              window.location.origin === "http://localhost:3000" ||
              window.location.origin === "https://prorider.in" ||
              window.location.origin === "https://www.prorider.in" ? (
                <DatabaseComponent />
              ) : (
                <HomeComponent />
              )
            }
          />
        </Route>
      </Routes>
    </>
  );
};

export default Root;
