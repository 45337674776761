import React, { useEffect, useState } from "react";

import { Badge, Button, Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import kfc from "../../../assets/images/kfc.png";
import rup_rnd from "../../../assets/images/icons/rup_rnd.svg";

import playStoreDownload from "../../../assets/images/playStoreDownload.png";
import bonus from "../../../assets/images/icons/bonus.png";
import petrol_bike from "../../../assets/images/icons/petrol_bike.svg";
import map_yellow from "../../../assets/images/icons/map_yellow.svg";
import vacancies from "../../../assets/images/icons/vacancies.svg";
import ComingSoonComponent from "../../components/coming_soon";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { PulseLoader } from "react-spinners";
function JobsComponent() {
  const [allJobs, setAllJobs] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [nextPage, setNextPage] = useState(false);
  const [per_page, setPerPage] = useState(12);
  const [page_no, setPageNo] = useState(1);
  const [showFullAddress, setShowFullAddress] = useState(false);
  // let per_page = 3;
  let next_page = false;
  useEffect(() => {
    getData(page_no);
  }, []);
  let text = "Scroll to load more data.";

  const getData = async (number) => {
    setIsLoading(true);
    axios
      .get(
        `https://api.prorider.in/api/v1/web/job-list?page_no=${number}&per_page=${per_page}`
      )

      .then((resp) => {
        setIsLoading(true);
        console.log("resp", resp);
        resp.data.data = [...allJobs, ...resp.data.data];
        setAllJobs(resp.data.data);
        setNextPage(resp.data.pagination.next_page);
        setIsLoading(false);
        next_page = resp.data.pagination.next_page;
        if (next_page) {
          setPageNo(page_no + 1);
        } else {
          text = "No more jobs.";
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        //   toast.error("Something went wrong!");
      });
  };
  const getNextPageData = () => {
    // page_no = page_no + 1;
    getData(page_no);
  };
  console.log("data-----------", allJobs);
  return (
    <>
      <section className="page_ylw_bnr secton_padding">
        <Container>
          <Row className="align-items-center">
            <Col sm="12" style={{ position: "relative" }}>
              <div className="text-center hdng_title">
                <h4 className="title_text m-0">jobs</h4>
              </div>
              <NavLink
                style={{
                  position: "absolute",
                  right: "0px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
                to="https://play.google.com/store/apps/details?id=com.prorider"
                target="_blank"
              >
                <img src={playStoreDownload} height="50px" />
              </NavLink>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="latest_job py-5">
        <Container className="">
          <Row className="g-3">
            <InfiniteScroll
              className="row g-3"
              dataLength={allJobs.length}
              next={getNextPageData}
              hasMore={nextPage}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  {" "}
                  {isLoading ? (
                    <div
                      style={{
                        // textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "50vh",
                        width: "100vw",
                      }}
                    >
                      <p>
                        <PulseLoader color="#fdc648" size={25} />
                      </p>
                    </div>
                  ) : (
                    "No new Latest Job"
                  )}{" "}
                </p>
              }
              loader={<h4>{text}</h4>}
            >
              {allJobs && allJobs.length > 0
                ? allJobs.map((job, index) => {
                    return (
                      <Col sm={6} lg={4}>
                        <div className="latest_job_card">
                          {/* <Badge bg="success">New</Badge> */}
                          <div className="latest_job_header">
                            <Row className="g-2">
                              <Col sm="auto">
                                <img
                                  src={job.company_logo}
                                  style={{
                                    borderRadius: "50%",
                                    height: "40px",
                                    width: "40px",
                                  }}
                                  alt="image"
                                />
                              </Col>
                              <Col>
                                <p>{job.job_profile}</p>
                                <small>{job.company_name}</small>
                              </Col>
                            </Row>
                          </div>
                          <div className="latest_job_body mt-2">
                            <Row className="g-1 gx-2">
                              <Col
                                sm="6"
                                style={{
                                  transition: "all 0.3s ease",
                                  height: showFullAddress ? "auto" : "100px", // Adjust size when 'Read More' is clicked
                                  overflow: "hidden", // Prevent overflow when collapsed
                                }}
                              >
                                <div className="d-flex align-items-start mb-2">
                                  <img
                                    width={25}
                                    className=""
                                    src={rup_rnd}
                                    alt="image"
                                  />
                                  <p>{job.earnUpto}</p>
                                </div>

                                <div className="d-flex align-items-start mb-2">
                                  <img
                                    width={25}
                                    className=""
                                    src={map_yellow}
                                    alt="image"
                                  />
                                  <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      setShowFullAddress(!showFullAddress)
                                    }
                                  >
                                    {showFullAddress ? (
                                      `${job.hubname}, ${job.city_name}`
                                    ) : (
                                      <>
                                        {job.hubname.substring(0, 20)}
                                        <span style={{ color: "#fdc648" }}>
                                          ... Read More
                                        </span>
                                      </>
                                    )}
                                  </p>
                                </div>
                              </Col>

                              <Col sm="6">
                                <div className="d-flex align-items-start mb-2">
                                  <img
                                    width={25}
                                    className=""
                                    src={bonus}
                                    alt="image"
                                  />
                                  <p>
                                    Joining Bonus:
                                    {job.joining_bonus == "0"
                                      ? "NA"
                                      : `Rs.${job.joining_bonus}`}
                                    {/* {job.ev_on_rent == "yes"
                                      ? "EV Bike"
                                      : job.bike_required == "yes"
                                      ? "Petrol Bike"
                                      : "Not Available"} */}
                                  </p>
                                </div>

                                <div className="d-flex align-items-start mb-2">
                                  <img
                                    width={25}
                                    className=""
                                    src={vacancies}
                                    alt="image"
                                  />
                                  <p>{job.vacancy} Vacancies</p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="latest_job_footer mt-2">
                            <Row className="g-1 align-items-center">
                              <Col sm="12" className="text-center">
                                <div className="mt-2 d-flex align-items-center justify-content-between">
                                  <p className="m-0">
                                    Posted On {job.posted_date}
                                  </p>
                                  <NavLink
                                    target="_blank"
                                    to="https://play.google.com/store/apps/details?id=com.prorider"
                                  >
                                    <Button variant="warning" className="px-3">
                                      Apply
                                    </Button>
                                  </NavLink>
                                </div>
                              </Col>
                              <Col>
                                <Row className="g-2">
                                  {/* <Col xs="6">
                                    <div className="d-grid">
                                      <Button size="sm" variant="secondary">
                                        Save
                                        <svg
                                          className="ms-2"
                                          width="12"
                                          height="15"
                                          viewBox="0 0 12 15"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M6.27459 11.3173L5.90699 10.9497L5.53939 11.3173L2.398 14.4587C1.73627 14.3615 1.22818 13.7914 1.22818 13.1027V2.70537C1.22818 1.9485 1.84187 1.33481 2.59874 1.33481H9.21524C9.97211 1.33481 10.5858 1.9485 10.5858 2.70537V13.1027C10.5858 13.7914 10.0777 14.3615 9.41597 14.4587L6.27459 11.3173Z"
                                            stroke="#424141"
                                            stroke-width="1.03974"
                                          />
                                        </svg>
                                      </Button>
                                    </div>
                                  </Col> */}
                                  {/* <Col xs="6">
                                    <div className="d-grid">
                                      <Button size="sm" variant="warning">
                                        View
                                      </Button>
                                    </div>
                                  </Col> */}
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    );
                  })
                : ""}

              {/* <Col sm={6} lg={4}>
                            <div className="latest_job_card">
                                <Badge bg="danger">Popular</Badge>
                                <div className="latest_job_header">
                                    <Row className="g-2">
                                        <Col sm="auto"><img src={kfc} alt="image" /></Col>
                                        <Col>
                                            <p>Food Delivery Specialist</p>
                                            <small>KFC</small>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="latest_job_body mt-2">
                                    <Row className="g-1 gx-2">
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={rup_rnd} alt="image" />
                                                <p>25,000 - 50,000/Month</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={map_yellow} alt="image" />
                                                <p>Mumbai, Maharashtra</p>
                                            </div>
                                        </Col>
                                        
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={petrol_bike} alt="image" />
                                                <p>petrol bike</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={vacancies} alt="image" />
                                                <p>5 Vacancies</p>
                                            </div>
                                        </Col>
                                    </Row>  
                                </div>
                                <div className="latest_job_footer mt-2">
                                    <Row className="g-1 align-items-center">
                                        <Col>
                                            <p className="m-0">Posted On 15th Apr 2024</p>
                                        </Col>
                                        <Col>
                                            <Row className="g-2">
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="secondary">Save 
                                                            <svg className="ms-2" width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.27459 11.3173L5.90699 10.9497L5.53939 11.3173L2.398 14.4587C1.73627 14.3615 1.22818 13.7914 1.22818 13.1027V2.70537C1.22818 1.9485 1.84187 1.33481 2.59874 1.33481H9.21524C9.97211 1.33481 10.5858 1.9485 10.5858 2.70537V13.1027C10.5858 13.7914 10.0777 14.3615 9.41597 14.4587L6.27459 11.3173Z" stroke="#424141" stroke-width="1.03974"/>
                                                            </svg>
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="warning">View</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col sm={6} lg={4}>
                            <div className="latest_job_card">
                                <div className="latest_job_header">
                                    <Row className="g-2">
                                        <Col sm="auto"><img src={kfc} alt="image" /></Col>
                                        <Col>
                                            <p>Food Delivery Specialist</p>
                                            <small>KFC</small>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="latest_job_body mt-2">
                                    <Row className="g-1 gx-2">
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={rup_rnd} alt="image" />
                                                <p>25,000 - 50,000/Month</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={map_yellow} alt="image" />
                                                <p>Mumbai, Maharashtra</p>
                                            </div>
                                        </Col>
                                        
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={petrol_bike} alt="image" />
                                                <p>petrol bike</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={vacancies} alt="image" />
                                                <p>5 Vacancies</p>
                                            </div>
                                        </Col>
                                    </Row>  
                                </div>
                                <div className="latest_job_footer mt-2">
                                    <Row className="g-1 align-items-center">
                                        <Col>
                                            <p className="m-0">Posted On 15th Apr 2024</p>
                                        </Col>
                                        <Col>
                                            <Row className="g-2">
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="secondary">Save 
                                                            <svg className="ms-2" width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.27459 11.3173L5.90699 10.9497L5.53939 11.3173L2.398 14.4587C1.73627 14.3615 1.22818 13.7914 1.22818 13.1027V2.70537C1.22818 1.9485 1.84187 1.33481 2.59874 1.33481H9.21524C9.97211 1.33481 10.5858 1.9485 10.5858 2.70537V13.1027C10.5858 13.7914 10.0777 14.3615 9.41597 14.4587L6.27459 11.3173Z" stroke="#424141" stroke-width="1.03974"/>
                                                            </svg>
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="warning">View</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col sm={6} lg={4}>
                            <div className="latest_job_card">
                                <Badge bg="success">New</Badge>
                                <div className="latest_job_header">
                                    <Row className="g-2">
                                        <Col sm="auto"><img src={kfc} alt="image" /></Col>
                                        <Col>
                                            <p>Food Delivery Specialist</p>
                                            <small>KFC</small>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="latest_job_body mt-2">
                                    <Row className="g-1 gx-2">
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={rup_rnd} alt="image" />
                                                <p>25,000 - 50,000/Month</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={map_yellow} alt="image" />
                                                <p>Mumbai, Maharashtra</p>
                                            </div>
                                        </Col>
                                        
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={petrol_bike} alt="image" />
                                                <p>petrol bike</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={vacancies} alt="image" />
                                                <p>5 Vacancies</p>
                                            </div>
                                        </Col>
                                    </Row>  
                                </div>
                                <div className="latest_job_footer mt-2">
                                    <Row className="g-1 align-items-center">
                                        <Col>
                                            <p className="m-0">Posted On 15th Apr 2024</p>
                                        </Col>
                                        <Col>
                                            <Row className="g-2">
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="secondary">Save 
                                                            <svg className="ms-2" width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.27459 11.3173L5.90699 10.9497L5.53939 11.3173L2.398 14.4587C1.73627 14.3615 1.22818 13.7914 1.22818 13.1027V2.70537C1.22818 1.9485 1.84187 1.33481 2.59874 1.33481H9.21524C9.97211 1.33481 10.5858 1.9485 10.5858 2.70537V13.1027C10.5858 13.7914 10.0777 14.3615 9.41597 14.4587L6.27459 11.3173Z" stroke="#424141" stroke-width="1.03974"/>
                                                            </svg>
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="warning">View</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col sm={6} lg={4}>
                            <div className="latest_job_card">
                                <div className="latest_job_header">
                                    <Row className="g-2">
                                        <Col sm="auto"><img src={kfc} alt="image" /></Col>
                                        <Col>
                                            <p>Food Delivery Specialist</p>
                                            <small>KFC</small>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="latest_job_body mt-2">
                                    <Row className="g-1 gx-2">
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={rup_rnd} alt="image" />
                                                <p>25,000 - 50,000/Month</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={map_yellow} alt="image" />
                                                <p>Mumbai, Maharashtra</p>
                                            </div>
                                        </Col>
                                        
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={petrol_bike} alt="image" />
                                                <p>petrol bike</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={vacancies} alt="image" />
                                                <p>5 Vacancies</p>
                                            </div>
                                        </Col>
                                    </Row>  
                                </div>
                                <div className="latest_job_footer mt-2">
                                    <Row className="g-1 align-items-center">
                                        <Col>
                                            <p className="m-0">Posted On 15th Apr 2024</p>
                                        </Col>
                                        <Col>
                                            <Row className="g-2">
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="secondary">Save 
                                                            <svg className="ms-2" width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.27459 11.3173L5.90699 10.9497L5.53939 11.3173L2.398 14.4587C1.73627 14.3615 1.22818 13.7914 1.22818 13.1027V2.70537C1.22818 1.9485 1.84187 1.33481 2.59874 1.33481H9.21524C9.97211 1.33481 10.5858 1.9485 10.5858 2.70537V13.1027C10.5858 13.7914 10.0777 14.3615 9.41597 14.4587L6.27459 11.3173Z" stroke="#424141" stroke-width="1.03974"/>
                                                            </svg>
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="warning">View</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col sm={6} lg={4}>
                            <div className="latest_job_card">
                                <div className="latest_job_header">
                                    <Row className="g-2">
                                        <Col sm="auto"><img src={kfc} alt="image" /></Col>
                                        <Col>
                                            <p>Food Delivery Specialist</p>
                                            <small>KFC</small>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="latest_job_body mt-2">
                                    <Row className="g-1 gx-2">
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={rup_rnd} alt="image" />
                                                <p>25,000 - 50,000/Month</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={map_yellow} alt="image" />
                                                <p>Mumbai, Maharashtra</p>
                                            </div>
                                        </Col>
                                        
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={petrol_bike} alt="image" />
                                                <p>petrol bike</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={vacancies} alt="image" />
                                                <p>5 Vacancies</p>
                                            </div>
                                        </Col>
                                    </Row>  
                                </div>
                                <div className="latest_job_footer mt-2">
                                    <Row className="g-1 align-items-center">
                                        <Col>
                                            <p className="m-0">Posted On 15th Apr 2024</p>
                                        </Col>
                                        <Col>
                                            <Row className="g-2">
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="secondary">Save 
                                                            <svg className="ms-2" width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.27459 11.3173L5.90699 10.9497L5.53939 11.3173L2.398 14.4587C1.73627 14.3615 1.22818 13.7914 1.22818 13.1027V2.70537C1.22818 1.9485 1.84187 1.33481 2.59874 1.33481H9.21524C9.97211 1.33481 10.5858 1.9485 10.5858 2.70537V13.1027C10.5858 13.7914 10.0777 14.3615 9.41597 14.4587L6.27459 11.3173Z" stroke="#424141" stroke-width="1.03974"/>
                                                            </svg>
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="warning">View</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col sm={6} lg={4}>
                            <div className="latest_job_card">
                                <div className="latest_job_header">
                                    <Row className="g-2">
                                        <Col sm="auto"><img src={kfc} alt="image" /></Col>
                                        <Col>
                                            <p>Food Delivery Specialist</p>
                                            <small>KFC</small>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="latest_job_body mt-2">
                                    <Row className="g-1 gx-2">
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={rup_rnd} alt="image" />
                                                <p>25,000 - 50,000/Month</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={map_yellow} alt="image" />
                                                <p>Mumbai, Maharashtra</p>
                                            </div>
                                        </Col>
                                        
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={petrol_bike} alt="image" />
                                                <p>petrol bike</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={vacancies} alt="image" />
                                                <p>5 Vacancies</p>
                                            </div>
                                        </Col>
                                    </Row>  
                                </div>
                                <div className="latest_job_footer mt-2">
                                    <Row className="g-1 align-items-center">
                                        <Col>
                                            <p className="m-0">Posted On 15th Apr 2024</p>
                                        </Col>
                                        <Col>
                                            <Row className="g-2">
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="secondary">Save 
                                                            <svg className="ms-2" width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.27459 11.3173L5.90699 10.9497L5.53939 11.3173L2.398 14.4587C1.73627 14.3615 1.22818 13.7914 1.22818 13.1027V2.70537C1.22818 1.9485 1.84187 1.33481 2.59874 1.33481H9.21524C9.97211 1.33481 10.5858 1.9485 10.5858 2.70537V13.1027C10.5858 13.7914 10.0777 14.3615 9.41597 14.4587L6.27459 11.3173Z" stroke="#424141" stroke-width="1.03974"/>
                                                            </svg>
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="warning">View</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col sm={6} lg={4}>
                            <div className="latest_job_card">
                                <Badge bg="danger">Popular</Badge>
                                <div className="latest_job_header">
                                    <Row className="g-2">
                                        <Col sm="auto"><img src={kfc} alt="image" /></Col>
                                        <Col>
                                            <p>Food Delivery Specialist</p>
                                            <small>KFC</small>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="latest_job_body mt-2">
                                    <Row className="g-1 gx-2">
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={rup_rnd} alt="image" />
                                                <p>25,000 - 50,000/Month</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={map_yellow} alt="image" />
                                                <p>Mumbai, Maharashtra</p>
                                            </div>
                                        </Col>
                                        
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={petrol_bike} alt="image" />
                                                <p>petrol bike</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={vacancies} alt="image" />
                                                <p>5 Vacancies</p>
                                            </div>
                                        </Col>
                                    </Row>  
                                </div>
                                <div className="latest_job_footer mt-2">
                                    <Row className="g-1 align-items-center">
                                        <Col>
                                            <p className="m-0">Posted On 15th Apr 2024</p>
                                        </Col>
                                        <Col>
                                            <Row className="g-2">
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="secondary">Save 
                                                            <svg className="ms-2" width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.27459 11.3173L5.90699 10.9497L5.53939 11.3173L2.398 14.4587C1.73627 14.3615 1.22818 13.7914 1.22818 13.1027V2.70537C1.22818 1.9485 1.84187 1.33481 2.59874 1.33481H9.21524C9.97211 1.33481 10.5858 1.9485 10.5858 2.70537V13.1027C10.5858 13.7914 10.0777 14.3615 9.41597 14.4587L6.27459 11.3173Z" stroke="#424141" stroke-width="1.03974"/>
                                                            </svg>
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="warning">View</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col sm={6} lg={4}>
                            <div className="latest_job_card">
                                <div className="latest_job_header">
                                    <Row className="g-2">
                                        <Col sm="auto"><img src={kfc} alt="image" /></Col>
                                        <Col>
                                            <p>Food Delivery Specialist</p>
                                            <small>KFC</small>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="latest_job_body mt-2">
                                    <Row className="g-1 gx-2">
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={rup_rnd} alt="image" />
                                                <p>25,000 - 50,000/Month</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={map_yellow} alt="image" />
                                                <p>Mumbai, Maharashtra</p>
                                            </div>
                                        </Col>
                                        
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={petrol_bike} alt="image" />
                                                <p>petrol bike</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={vacancies} alt="image" />
                                                <p>5 Vacancies</p>
                                            </div>
                                        </Col>
                                    </Row>  
                                </div>
                                <div className="latest_job_footer mt-2">
                                    <Row className="g-1 align-items-center">
                                        <Col>
                                            <p className="m-0">Posted On 15th Apr 2024</p>
                                        </Col>
                                        <Col>
                                            <Row className="g-2">
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="secondary">Save 
                                                            <svg className="ms-2" width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.27459 11.3173L5.90699 10.9497L5.53939 11.3173L2.398 14.4587C1.73627 14.3615 1.22818 13.7914 1.22818 13.1027V2.70537C1.22818 1.9485 1.84187 1.33481 2.59874 1.33481H9.21524C9.97211 1.33481 10.5858 1.9485 10.5858 2.70537V13.1027C10.5858 13.7914 10.0777 14.3615 9.41597 14.4587L6.27459 11.3173Z" stroke="#424141" stroke-width="1.03974"/>
                                                            </svg>
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="warning">View</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col sm={6} lg={4}>
                            <div className="latest_job_card">
                                <Badge bg="success">New</Badge>
                                <div className="latest_job_header">
                                    <Row className="g-2">
                                        <Col sm="auto"><img src={kfc} alt="image" /></Col>
                                        <Col>
                                            <p>Food Delivery Specialist</p>
                                            <small>KFC</small>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="latest_job_body mt-2">
                                    <Row className="g-1 gx-2">
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={rup_rnd} alt="image" />
                                                <p>25,000 - 50,000/Month</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={map_yellow} alt="image" />
                                                <p>Mumbai, Maharashtra</p>
                                            </div>
                                        </Col>
                                        
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={petrol_bike} alt="image" />
                                                <p>petrol bike</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={vacancies} alt="image" />
                                                <p>5 Vacancies</p>
                                            </div>
                                        </Col>
                                    </Row>  
                                </div>
                                <div className="latest_job_footer mt-2">
                                    <Row className="g-1 align-items-center">
                                        <Col>
                                            <p className="m-0">Posted On 15th Apr 2024</p>
                                        </Col>
                                        <Col>
                                            <Row className="g-2">
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="secondary">Save 
                                                            <svg className="ms-2" width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.27459 11.3173L5.90699 10.9497L5.53939 11.3173L2.398 14.4587C1.73627 14.3615 1.22818 13.7914 1.22818 13.1027V2.70537C1.22818 1.9485 1.84187 1.33481 2.59874 1.33481H9.21524C9.97211 1.33481 10.5858 1.9485 10.5858 2.70537V13.1027C10.5858 13.7914 10.0777 14.3615 9.41597 14.4587L6.27459 11.3173Z" stroke="#424141" stroke-width="1.03974"/>
                                                            </svg>
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="warning">View</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col sm={6} lg={4}>
                            <div className="latest_job_card">
                                <div className="latest_job_header">
                                    <Row className="g-2">
                                        <Col sm="auto"><img src={kfc} alt="image" /></Col>
                                        <Col>
                                            <p>Food Delivery Specialist</p>
                                            <small>KFC</small>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="latest_job_body mt-2">
                                    <Row className="g-1 gx-2">
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={rup_rnd} alt="image" />
                                                <p>25,000 - 50,000/Month</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={map_yellow} alt="image" />
                                                <p>Mumbai, Maharashtra</p>
                                            </div>
                                        </Col>
                                        
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={petrol_bike} alt="image" />
                                                <p>petrol bike</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={vacancies} alt="image" />
                                                <p>5 Vacancies</p>
                                            </div>
                                        </Col>
                                    </Row>  
                                </div>
                                <div className="latest_job_footer mt-2">
                                    <Row className="g-1 align-items-center">
                                        <Col>
                                            <p className="m-0">Posted On 15th Apr 2024</p>
                                        </Col>
                                        <Col>
                                            <Row className="g-2">
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="secondary">Save 
                                                            <svg className="ms-2" width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.27459 11.3173L5.90699 10.9497L5.53939 11.3173L2.398 14.4587C1.73627 14.3615 1.22818 13.7914 1.22818 13.1027V2.70537C1.22818 1.9485 1.84187 1.33481 2.59874 1.33481H9.21524C9.97211 1.33481 10.5858 1.9485 10.5858 2.70537V13.1027C10.5858 13.7914 10.0777 14.3615 9.41597 14.4587L6.27459 11.3173Z" stroke="#424141" stroke-width="1.03974"/>
                                                            </svg>
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="warning">View</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col sm={6} lg={4}>
                            <div className="latest_job_card">
                                <div className="latest_job_header">
                                    <Row className="g-2">
                                        <Col sm="auto"><img src={kfc} alt="image" /></Col>
                                        <Col>
                                            <p>Food Delivery Specialist</p>
                                            <small>KFC</small>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="latest_job_body mt-2">
                                    <Row className="g-1 gx-2">
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={rup_rnd} alt="image" />
                                                <p>25,000 - 50,000/Month</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={map_yellow} alt="image" />
                                                <p>Mumbai, Maharashtra</p>
                                            </div>
                                        </Col>
                                        
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={petrol_bike} alt="image" />
                                                <p>petrol bike</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={vacancies} alt="image" />
                                                <p>5 Vacancies</p>
                                            </div>
                                        </Col>
                                    </Row>  
                                </div>
                                <div className="latest_job_footer mt-2">
                                    <Row className="g-1 align-items-center">
                                        <Col>
                                            <p className="m-0">Posted On 15th Apr 2024</p>
                                        </Col>
                                        <Col>
                                            <Row className="g-2">
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="secondary">Save 
                                                            <svg className="ms-2" width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.27459 11.3173L5.90699 10.9497L5.53939 11.3173L2.398 14.4587C1.73627 14.3615 1.22818 13.7914 1.22818 13.1027V2.70537C1.22818 1.9485 1.84187 1.33481 2.59874 1.33481H9.21524C9.97211 1.33481 10.5858 1.9485 10.5858 2.70537V13.1027C10.5858 13.7914 10.0777 14.3615 9.41597 14.4587L6.27459 11.3173Z" stroke="#424141" stroke-width="1.03974"/>
                                                            </svg>
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="warning">View</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col sm={6} lg={4}>
                            <div className="latest_job_card">
                                <div className="latest_job_header">
                                    <Row className="g-2">
                                        <Col sm="auto"><img src={kfc} alt="image" /></Col>
                                        <Col>
                                            <p>Food Delivery Specialist</p>
                                            <small>KFC</small>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="latest_job_body mt-2">
                                    <Row className="g-1 gx-2">
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={rup_rnd} alt="image" />
                                                <p>25,000 - 50,000/Month</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={map_yellow} alt="image" />
                                                <p>Mumbai, Maharashtra</p>
                                            </div>
                                        </Col>
                                        
                                        <Col sm={6}>
                                            <div className="d-flex align-items-center">
                                                <img className="" src={petrol_bike} alt="image" />
                                                <p>petrol bike</p>
                                            </div>
                                            
                                            <div className="d-flex align-items-center">
                                                <img className="" src={vacancies} alt="image" />
                                                <p>5 Vacancies</p>
                                            </div>
                                        </Col>
                                    </Row>  
                                </div>
                                <div className="latest_job_footer mt-2">
                                    <Row className="g-1 align-items-center">
                                        <Col>
                                            <p className="m-0">Posted On 15th Apr 2024</p>
                                        </Col>
                                        <Col>
                                            <Row className="g-2">
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="secondary">Save 
                                                            <svg className="ms-2" width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M6.27459 11.3173L5.90699 10.9497L5.53939 11.3173L2.398 14.4587C1.73627 14.3615 1.22818 13.7914 1.22818 13.1027V2.70537C1.22818 1.9485 1.84187 1.33481 2.59874 1.33481H9.21524C9.97211 1.33481 10.5858 1.9485 10.5858 2.70537V13.1027C10.5858 13.7914 10.0777 14.3615 9.41597 14.4587L6.27459 11.3173Z" stroke="#424141" stroke-width="1.03974"/>
                                                            </svg>
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="d-grid">
                                                        <Button size="sm" variant="warning">View</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col> */}
            </InfiniteScroll>
          </Row>
        </Container>
      </section>
      {/* Coming Soon Start here */}
      <ComingSoonComponent />
      {/* Coming Soon End here */}
    </>
  );
}

export default JobsComponent;
