import React, { useState } from "react";
import ComingSoonComponent from "../../components/coming_soon";
import { Button, Col, Container, Nav, Row, Tab, Modal } from "react-bootstrap";

import prorider_ind_map from '../../../assets/images/prorider_ind_map.jpeg';
import verified_check from '../../../assets/images/icons/verified_check.png';
import download_db from '../../../assets/images/icons/download_db.png';
import verified_close from '../../../assets/images/icons/verified_close.png';
import audio_ad from '../../../assets/images/icons/audio_ad.png';
import video_ad from '../../../assets/images/icons/video_ad.png';
import splash_ad from '../../../assets/images/icons/splash_ad.png';
import push_notifications from '../../../assets/images/icons/push_notifications.png';
import data_download from '../../../assets/images/icons/data_download.png';
import gif_ads from '../../../assets/images/icons/gif_ads.png';
import static_ad from '../../../assets/images/icons/static_ad.png';
import offer_section_post from '../../../assets/images/icons/offer_section_post.png';
import add_on_cities from '../../../assets/images/icons/add_on_cities.png';
import add_job_posts from '../../../assets/images/icons/add_job_posts.png';
import sms_content from '../../../assets/images/icons/sms_content.png';
import deliver_scooter from '../../../assets/images/icons/deliver_scooter.png';


import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";



function DatabaseComponent (props){

    const [isAddAudioActive, SetAddAudioButtonActive] = useState(0);
    const toggleAddAudioButton = () => {
        SetAddAudioButtonActive(!isAddAudioActive);
    };

    const [isAddVidioActive, SetAddVideoButtonActive] = useState(0);
    const toggleAddVideoButton = () => {
        SetAddVideoButtonActive(!isAddVidioActive);
    };

    const [isAddSplashActive, SetAddSplashButtonActive] = useState(0);
    const toggleAddSplashButton = () => {
        SetAddSplashButtonActive(!isAddSplashActive);
    };

    const [isAddNotificationsActive, SetAddNotificationsButtonActive] = useState(0);
    const toggleAddNotificationsButton = () => {
        SetAddNotificationsButtonActive(!isAddNotificationsActive);
    };

    
    const [isAddDDownloadActive, SetAddDDownloadButtonActive] = useState(0);
    const toggleAddDDownloadButton = () => {
        SetAddDDownloadButtonActive(!isAddDDownloadActive);
    };
  
    const [isAddGifAdsActive, SetAddGifAdsButtonActive] = useState(0);
    const toggleAddGifAdsButton = () => {
        SetAddGifAdsButtonActive(!isAddGifAdsActive);
    };
  
    const [isAddStaticActive, SetAddStaticButtonActive] = useState(0);
    const toggleAddStaticButton = () => {
        SetAddStaticButtonActive(!isAddStaticActive);
    };
  
    const [isAddOfferSectionActive, SetAddOfferSectionButtonActive] = useState(0);
    const toggleAddOfferSectionButton = () => {
        SetAddOfferSectionButtonActive(!isAddOfferSectionActive);
    };
  
    const [isAddAddCitiesActive, SetAddAddCitiesButtonActive] = useState(0);
    const toggleAddAddCitiesButton = () => {
        SetAddAddCitiesButtonActive(!isAddAddCitiesActive);
    };
  
    const [isAddJobPostsActive, SetAddJobPostsButtonActive] = useState(0);
    const toggleAddJobPostsButton = () => {
        SetAddJobPostsButtonActive(!isAddJobPostsActive);
    };
  
    const [isAddSMSContentActive, SetAddSMSContentButtonActive] = useState(0);
    const toggleAddSMSContentButton = () => {
        SetAddSMSContentButtonActive(!isAddSMSContentActive);
    };


    const [showBeginnerPlanModal, setshowBeginnerPlanModal] = useState(false);
    const handleBeginnerPlanModalClose = () => setshowBeginnerPlanModal(false);
    const handleBeginnerPlanModalShow = () => setshowBeginnerPlanModal(true);

    const [showAdvancedPlanModal, setshowAdvancedPlanModal] = useState(false);
    const handleAdvancedPlanModalClose = () => setshowAdvancedPlanModal(false);
    const handleAdvancedPlanModalShow = () => setshowAdvancedPlanModal(true);

    const [showProPlanModal, setshowProPlanModal] = useState(false);
    const handleProPlanModalClose = () => setshowProPlanModal(false);
    const handleProPlanModalShow = () => setshowProPlanModal(true);

    
  const [company_name, setCompanyName] = useState("");
  const [full_name, setFullName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [messagem, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      company_name: company_name,
      full_name: full_name,
      mobile: mobile,
      email: email,
      description: description,
    };

    axios
      .post("https://api.prorider.in/api/v1/web/jobPostRequest", payload)
      .then((resp) => {
        // console.log(resp);
        setIsLoading(true);
        setMessage(resp.data.message);
        toast.success("Request submitted!");
        setTimeout(() => {
          setIsLoading(false);
          window.location.assign("/");
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };
    return(
        <>
            <section className="page_ylw_bnr secton_padding">
                <Container>
                    <Row className="align-items-center justify-content-between">
                        <Col lg="12">
                            <div className="text-lg-start text-center hdng_title">
                                <h4 className="title_text m-0">ProRider <br/>Verified Database</h4>
                                <h6>Download customised and curated verified Database of Delivery Riders from India's growing workforce.</h6>
                            </div>
                        </Col>
                    </Row>

                    <Row className="g-3 align-items-center justify-content-lg-between justify-content-center mt-5">
                        <Col sm="5">
                            <div className="verified_check_list">
                                <img src={verified_check} />
                                <p>PAN & AADHAR verified riders</p>
                            </div>
                            <div className="verified_check_list">
                                <img src={verified_check} />
                                <p>Pan India Database - All Metro's & Non Metro's</p>
                            </div>
                            <div className="verified_check_list">
                                <img src={verified_check} />
                                <p>Database Customization as per User Requirements</p>
                            </div>
                            <div className="verified_check_list">
                                <img src={verified_check} />
                                <p><b className="fw-700">3.5 Mn+</b> Active Rider Database available</p>
                            </div>
                            <NavLink to="/post-a-job" className="download_database_btn">
                            <img src={download_db} />
                                <span>Download Database</span>
                            </NavLink>
                        </Col>

                        <Col sm="5">
                            {/* <img className="" src={ind_map} alt="image"/> */}
                            
                            <div className="ind_map_card"> 
                                <img src={prorider_ind_map} alt="images" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="ad_packages">
                <Container>
                    <Row className="justify-content-center">
                        <Col className="col-sm-auto">
                            <div className="text-center hdng_title">
                                <h4 className="title_text">AD Packages</h4>
                            </div>
                            <div className="process_registration">
                                <h6>Process For Registration</h6>
                                <p>Upload Your Company Information along with
                                Company PAN Card & User Credentials</p>
                            </div>
                        </Col>
                    </Row>
                    <Row> 
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <div className="pills_round_bg">
                                <Nav  className="justify-content-center">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Packages</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">ADD Ons</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>

                            <Tab.Content className="mt-5">
                                <Tab.Pane eventKey="first">
                                    <Row className="g-0 dv_th_td_row">
                                        <Col  className="d-none d-sm-block col dv_th_td_col">
                                            <div className="dv_th_td"></div>
                                            <div className="dv_th_td">
                                                <p>Number of Job post & City Limit</p>
                                            </div>
                                            <div className="dv_th_td">
                                                <p>Validity</p>
                                            </div>
                                            <div className="dv_th_td">
                                                <p>Applications</p>
                                            </div>
                                            <div className="dv_th_td">
                                                <p>Bulk Database Download</p>
                                            </div>
                                            <div className="dv_th_td">
                                                <p>Job Visibility</p>
                                            </div>
                                            <div className="dv_th_td">
                                                <p>Freebies</p>
                                            </div>
                                            <div className="dv_th_td">
                                                <div>
                                                    <p>Splash AD Post</p>
                                                    <small>Visibility on the Landing Page</small>
                                                </div>
                                            </div>
                                            <div className="dv_th_td">
                                                <div>
                                                    <h5>Package Rates</h5>
                                                    <NavLink>Talk To us</NavLink>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col  className="col dv_th_td_col">
                                            <div className="dv_th_td">
                                                <h6>Free Plan</h6>
                                            </div>
                                            <div className="dv_th_td">
                                                <div>
                                                    <p>3 Jobs</p>
                                                    <small>Free Limited to 3 City</small>
                                                </div>
                                            </div>
                                            <div className="dv_th_td">
                                                <p>15 Days</p>
                                            </div>
                                            <div className="dv_th_td">
                                                <p>25 free Applications</p>
                                            </div>
                                            <div className="dv_th_td">
                                                <img src={verified_close} alt="image" />
                                            </div>
                                            <div className="dv_th_td">
                                                <img src={verified_close} alt="image" />
                                            </div>
                                            <div className="dv_th_td">
                                                <img src={verified_close} alt="image" />
                                            </div>
                                            <div className="dv_th_td">
                                                <img src={verified_close} alt="image" />
                                            </div>
                                            <div className="dv_th_td">
                                                <NavLink className="btn" to="/post-a-job">Contact us</NavLink>
                                            </div>
                                        </Col>

                                        <Col  className="col dv_th_td_col">
                                            <div className="dv_th_td">
                                                <h6>Beginner Plan</h6>
                                            </div>
                                            <div className="dv_th_td">
                                                <div>
                                                    <p>3 Jobs</p>
                                                    <small>Free Limited to 1 City</small>
                                                </div>
                                            </div>
                                            <div className="dv_th_td">
                                                <p>15 Days</p>
                                            </div>
                                            <div className="dv_th_td">
                                                <p>25 free Applications</p>
                                            </div>
                                            <div className="dv_th_td">
                                                <p>300 Free Downloads</p>
                                            </div>
                                            <div className="dv_th_td">
                                                <p>Top Job Feed 4 Days
                                                2 time slots</p>
                                            </div>
                                            <div className="dv_th_td">
                                                <div>
                                                    <img className="d-block" src={verified_check} alt="image" />
                                                    <NavLink  onClick={handleBeginnerPlanModalShow}>Click To see</NavLink>
                                                    <Modal centered className="freebies_modal" show={showBeginnerPlanModal} onHide={handleBeginnerPlanModalClose}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>
                                                                <h5>Freebies</h5>
                                                            </Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <ul>
                                                                <li>ProRider will Design your Static Job Post.</li>
                                                                <li>Company logo will feature on the App Home Screen.</li>
                                                            </ul>
                                                        </Modal.Body>
                                                    </Modal>
                                                </div>
                                            </div>
                                            <div className="dv_th_td">
                                                <div>
                                                    <p>4 Days</p>
                                                    <p>2 time slots</p>
                                                </div>
                                            </div>
                                            <div className="dv_th_td">
                                                <NavLink className="btn" to="/post-a-job">Contact us</NavLink>
                                            </div>
                                        </Col>

                                        <Col  className="col dv_th_td_col">
                                            <div className="dv_th_td">
                                                <h6>Advanced Plan</h6>
                                            </div>
                                            <div className="dv_th_td">
                                                <div>
                                                    <p>10 Jobs</p>
                                                    <small>Free Limited to 10 City</small>
                                                </div>
                                            </div>
                                            <div className="dv_th_td">
                                                <p>30 Days</p>
                                            </div>
                                            <div className="dv_th_td">
                                                <p className="text_unlimited">Unlimited</p>
                                            </div>
                                            <div className="dv_th_td">
                                                <p>1000 Free Downloads</p>
                                            </div>
                                            <div className="dv_th_td">
                                                <p>Top Job Feed 8 Days
                                                8 time slots</p>
                                            </div>
                                            <div className="dv_th_td">
                                                <div>
                                                    <img className="d-block" src={verified_check} alt="image" />
                                                    <NavLink  onClick={handleAdvancedPlanModalShow}>Click To see</NavLink>
                                                    <Modal centered className="freebies_modal" show={showAdvancedPlanModal} onHide={handleAdvancedPlanModalClose}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>
                                                                <h5>Freebies</h5>
                                                            </Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <ul>
                                                                <li>ProRider will Design your Static Job Post.</li>
                                                                <li>10 Second Video AD will be showed to the candidate 4 times in 30 Days in 4 time slots on the Offers / Job Search Page.</li>
                                                                <li>Footer ADs will be shown to the candidates for 8 Days and 8 time slots.</li>
                                                                <li>Your Incentives & Benefits will feature on the ProOffers Section for 8 days.</li>
                                                                <li>Company logo will feature on the App Home Screen.</li>
                                                                <li>Push notifications will be sent to the rider for 8 days and 8 time slots.</li>
                                                            </ul>
                                                        </Modal.Body>
                                                    </Modal>
                                                </div>
                                            </div>
                                            <div className="dv_th_td">
                                                <div>
                                                    <p>8 Days</p>
                                                    <p>8 time slots</p>
                                                </div>
                                            </div>
                                            <div className="dv_th_td">
                                                <NavLink className="btn" to="/post-a-job">Contact us</NavLink>
                                            </div>
                                        </Col>

                                        <Col  className="col dv_th_td_col">
                                            <div className="dv_th_td">
                                                <h6>Pro Plan</h6>
                                            </div>
                                            <div className="dv_th_td">
                                                <div>
                                                    <p>20 Jobs</p>
                                                    <small>Free - Pan India Visibility</small>
                                                </div>
                                            </div>
                                            <div className="dv_th_td">
                                                <p>60 Days</p>
                                            </div>
                                            <div className="dv_th_td">
                                                <p className="text_unlimited">Unlimited</p>
                                            </div>
                                            <div className="dv_th_td">
                                                <p>2500 Free Downloads</p>
                                            </div>
                                            <div className="dv_th_td">
                                                <p>Top Job Feed 20 Days
                                                20 time slots</p>
                                            </div>
                                            <div className="dv_th_td">
                                                <div>
                                                    <img className="d-block" src={verified_check} alt="image" />
                                                    <NavLink  onClick={handleProPlanModalShow}>Click To see</NavLink>
                                                    <Modal centered className="freebies_modal" show={showProPlanModal} onHide={handleProPlanModalClose}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>
                                                                <h5>Freebies</h5>
                                                            </Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <ul>
                                                                <li>ProRider will Design your Static, GIF Job, Audio & Video Job Post.</li>
                                                                <li>10 Second Video AD will be showed to the candidate 8 times in 60 Days in 8 time slots on the Offers / Job Search Page.</li>
                                                                <li>Native & Footer ADs will be shown to the candidates for 20 Days and 20 time slots. Your Incentives & Benefits will feature on the ProOffers Section for 20 days.</li>
                                                                <li>Company logo will feature on the App Home Screen.</li>
                                                                <li>Push notifications will be sent to the rider for 20 days and 20 time slots.</li>
                                                                <li>Video AD of 10 seconds will be shared with the candidates when they log in for 20 Days once a day.</li>
                                                                <li>Audio AD for 20 Days in 20 time slots.</li>
                                                                <li>Push Notifications will be sent once a day for 20 Days in 20 time slots.</li>
                                                            </ul>
                                                        </Modal.Body>
                                                    </Modal>
                                                </div>
                                            </div>
                                            <div className="dv_th_td">
                                                <div>
                                                    <p>20 Days</p>
                                                    <p>20 time slots</p>
                                                </div>
                                            </div>
                                            <div className="dv_th_td">
                                            <NavLink className="btn" to="/post-a-job">Contact us</NavLink>
                                            </div>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <Row className="g-3 justify-content-center">
                                        <Col lg="4" sm="6">
                                            <div className={isAddAudioActive ? 'add_ons_card' : 'add_ons_card active'}>
                                                <Row>
                                                    <Col>
                                                        <div className="d-flex align-items-center">
                                                            <img className="me-2" src={audio_ad} />
                                                            <span>Audio AD</span>
                                                        </div>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Button onClick={toggleAddAudioButton} className="btn_add">
                                                            {isAddAudioActive ? 'Add' : 'Added'}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col lg="4" sm="6">
                                            <div className={isAddVidioActive ? 'add_ons_card' : 'add_ons_card active'}>
                                                <Row>
                                                    <Col>
                                                        <div className="d-flex align-items-center">
                                                            <img className="me-2" src={video_ad} />
                                                            <span>Video AD</span>
                                                        </div>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Button onClick={toggleAddVideoButton} className="btn_add">
                                                            {isAddVidioActive? 'Add' : 'Added'}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col lg="4" sm="6">
                                            <div className={isAddSplashActive ? 'add_ons_card' : 'add_ons_card active'}>
                                                <Row>
                                                    <Col>
                                                        <div className="d-flex align-items-center">
                                                            <img className="me-2" src={splash_ad} />
                                                            <span>Splash AD</span>
                                                        </div>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Button onClick={toggleAddSplashButton} className="btn_add">
                                                            {isAddSplashActive ? 'Add' : 'Added'}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col lg="4" sm="6">
                                            <div className={isAddNotificationsActive ? 'add_ons_card' : 'add_ons_card active'}>
                                                <Row>
                                                    <Col>
                                                        <div className="d-flex align-items-center">
                                                            <img className="me-2" src={push_notifications} />
                                                            <span>Push Notifications</span>
                                                        </div>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Button onClick={toggleAddNotificationsButton} className="btn_add">
                                                            {isAddNotificationsActive ? 'Add' : 'Added'}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col lg="4" sm="6">
                                            <div className={isAddDDownloadActive ? 'add_ons_card' : 'add_ons_card active'}>
                                                <Row>
                                                    <Col>
                                                        <div className="d-flex align-items-center">
                                                            <img className="me-2" src={data_download} />
                                                            <span>Data Download</span>
                                                        </div>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Button onClick={toggleAddDDownloadButton} className="btn_add">
                                                            {isAddDDownloadActive ? 'Add' : 'Added'}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col lg="4" sm="6">
                                            <div className={isAddGifAdsActive? 'add_ons_card' : 'add_ons_card active'}>
                                                <Row>
                                                    <Col>
                                                        <div className="d-flex align-items-center">
                                                            <img className="me-2" src={gif_ads} />
                                                            <span>GIF ADs</span>
                                                        </div>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Button onClick={toggleAddGifAdsButton} className="btn_add">
                                                            {isAddGifAdsActive ? 'Add' : 'Added'}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>

                                        <Col lg="4" sm="6">
                                            <div className={isAddStaticActive ? 'add_ons_card' : 'add_ons_card active'}>
                                                <Row>
                                                    <Col>
                                                        <div className="d-flex align-items-center">
                                                            <img className="me-2" src={static_ad} />
                                                            <span>static AD</span>
                                                        </div>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Button onClick={toggleAddStaticButton} className="btn_add">
                                                            {isAddStaticActive ? 'Add' : 'Added'}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col lg="4" sm="6">
                                            <div className={isAddOfferSectionActive ? 'add_ons_card' : 'add_ons_card active'}>
                                                <Row>
                                                    <Col>
                                                        <div className="d-flex align-items-center">
                                                            <img className="me-2" src={offer_section_post} />
                                                            <span>Offer Section Post</span>
                                                        </div>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Button onClick={toggleAddOfferSectionButton} className="btn_add">
                                                            {isAddOfferSectionActive ? 'Add' : 'Added'}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col lg="4" sm="6">
                                            <div className={isAddAddCitiesActive ? 'add_ons_card' : 'add_ons_card active'}>
                                                <Row>
                                                    <Col>
                                                        <div className="d-flex align-items-center">
                                                            <img className="me-2" src={add_on_cities} />
                                                            <span>Add On Cities</span>
                                                        </div>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Button onClick={toggleAddAddCitiesButton} className="btn_add">
                                                            {isAddAddCitiesActive ? 'Add' : 'Added'}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col lg="4" sm="6">
                                            <div className={isAddJobPostsActive ? 'add_ons_card' : 'add_ons_card active'}>
                                                <Row>
                                                    <Col>
                                                        <div className="d-flex align-items-center">
                                                            <img className="me-2" src={add_job_posts} />
                                                            <span>Add On Job Posts</span>
                                                        </div>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Button onClick={toggleAddJobPostsButton} className="btn_add">
                                                            {isAddJobPostsActive ? 'Add' : 'Added'}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col lg="4" sm="6">
                                            <div className={isAddSMSContentActive ? 'add_ons_card' : 'add_ons_card active'}>
                                                <Row>
                                                    <Col>
                                                        <div className="d-flex align-items-center">
                                                            <img className="me-2" src={sms_content} />
                                                            <span>SMS Content</span>
                                                        </div>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Button onClick={toggleAddSMSContentButton} className="btn_add">
                                                            {isAddSMSContentActive ? 'Add' : 'Added'}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>

                                        <Col sm="12" className="text-center mt-5">
                                            <NavLink to="/post-a-job" className="add_ons_contactus_btn">Contact us</NavLink>
                                        </Col>

                                    </Row>
                                </Tab.Pane>
                            </Tab.Content>
                    
                        </Tab.Container>
                    </Row>
                </Container>
            </section>


            {/* <section className="contact_us secton_padding ">
                <div>
                <Container>
                    <Row>
                    <Col>
                        <div className="hdng_title">
                        <h4 className="title_text">Post A  Free Job</h4>
                        </div>
                    </Col>
                    </Row>
                    <Row className="">
                    <Col md="6">
                        <div className="contact_us_card">
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="">
                            <Form.Label>Company/Vendor Name</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                placeholder="Enter Company/Vendor Name"
                                onChange={(e) => {
                                setCompanyName(e.target.value);
                                }}
                            />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="">
                            <Form.Label>Contact Person Name</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                placeholder="Enter Contact Person Name"
                                onChange={(e) => {
                                setFullName(e.target.value);
                                }}
                            />
                            </Form.Group>

                            <Row>
                            <Col md="6">
                                <Form.Group className="mb-3" controlId="">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    required
                                    placeholder="Enter Email"
                                    onChange={(e) => {
                                    setEmail(e.target.value);
                                    }}
                                />
                                </Form.Group>
                            </Col>
                            <Col md="6">
                                <Form.Group className="mb-3" controlId="">
                                <Form.Label>Phone No.</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="Enter Phone No."
                                    onChange={(e) => {
                                    setMobile(e.target.value);
                                    }}
                                />
                                </Form.Group>
                            </Col>
                            </Row>

                            <Form.Group className="mb-3" controlId="">
                            <Form.Label>Whats Your Requirement? </Form.Label>
                            <Form.Control
                                as="textarea"
                                required
                                rows={4}
                                placeholder="Write something..."
                                onChange={(e) => {
                                setDescription(e.target.value);
                                }}
                            />
                            </Form.Group>
                            <Button variant="light" size="md" type="submit">
                            Submit
                            </Button>
                        </Form>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="h-100">
                        <img className="h-100" src={contact_us} alt="image" />
                        </div>
                    </Col>
                    </Row>
                </Container>
                </div>
            </section> */}

        {/* Coming Soon Start here */}
        <ComingSoonComponent />
        {/* Coming Soon End here */}
        </>
    )
}

export default DatabaseComponent;