import React, { useEffect, useState } from "react";
import ComingSoonComponent from "../../components/coming_soon";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import axios from "axios";
import renderHTML from "react-render-html";
import { toast } from "react-toastify";
import our_blogs_img1 from "../../../assets/images/our_blogs_img1.png";
import our_blogs_img2 from "../../../assets/images/our_blogs_img2.png";
import our_blogs_img3 from "../../../assets/images/our_blogs_img3.png";

function BlogsComponent() {
  const [allBlogs, setAllBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
    getData();
  }, []);

  const getData = async () => {
    await axios
      .get("https://api.prorider.in/api/v1/blogs/list")
      .then((resp) => {
        // console.log(resp);
        setIsLoading(false);
        setAllBlogs(resp.data.data);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Something went wrong!");
      });
  };

  return (
    <>
      <section className="page_ylw_bnr secton_padding">
        <Container>
          <Row className="align-items-center">
            <Col sm="12">
              <div className="text-center hdng_title">
                <h4 className="title_text m-0">Industry Updates And News</h4>
                <h6>Delivery Pro Tips & Industry Insights</h6>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Our Blogs Start here */}
      <section className="our_blogs py-5">
        <Container className="">
          <Row className="g-3 gy-4">
            {isLoading === false &&
              allBlogs.length > 0 &&
              allBlogs.map((blog) => (
                <Col sm="6" lg="4">
                  <div className="our_blogs_card">
                    <div className="our_blogs_card_hdr">
                      <NavLink to={"/blog-details-1/" + blog?.id}>
                        <img
                          src={
                            blog.image !== null ? blog.image : our_blogs_img1
                            // our_blogs_img1
                          }
                          alt="image"
                          style={{
                            height: "200px",
                            objectFit: "cover",
                          }}
                        />
                      </NavLink>
                    </div>
                    <div className="our_blogs_card_body">
                      <h5>{blog?.title}</h5>

                      <p>
                        {renderHTML(
                          blog?.content.length > 40
                            ? blog.content.substring(0, 120) + "..."
                            : blog.content
                        )}
                      </p>
                      <div className="d-flex align-items-center justify-content-between">
                        <small> {blog.blog_date}</small>
                        <NavLink to={"/blog-details-1/" + blog?.id}>
                          Read More
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </section>
      {/* Our Blogs End here */}

      {/* Coming Soon Start here */}
      <ComingSoonComponent />
      {/* Coming Soon End here */}
    </>
  );
}

export default BlogsComponent;
