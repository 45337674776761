import React, { useContext, useEffect, useState } from "react";

import { NavLink } from "react-router-dom";
import {
  Accordion,
  AccordionContext,
  useAccordionButton,
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
} from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import siddharth from "../../../assets/images/siddharth.jpg";
import neehar from "../../../assets/images/neehar.jpg";
import home_banner1 from "../../../assets/images/home_banner1.jpg";
import potential_icon from "../../../assets/images/icons/potential_icon.png";
import eye_icon from "../../../assets/images/icons/eye_icon.png";
import diamond_icon from "../../../assets/images/icons/diamond_icon.png";
import rup_rnd from "../../../assets/images/icons/rup_rnd.svg";
import dgt1 from "../../../assets/images/dgt1.png";
import dgt2 from "../../../assets/images/dgt2.png";
import dgt3 from "../../../assets/images/dgt3.png";

import bonus from "../../../assets/images/icons/bonus.png";
import petrol_bike from "../../../assets/images/icons/petrol_bike.svg";
import map_yellow from "../../../assets/images/icons/map_yellow.svg";
import vacancies from "../../../assets/images/icons/vacancies.svg";
import how_it_works from "../../../assets/images/how_it_work.png";
import kfc from "../../../assets/images/kfc.png";
import dummy_user from "../../../assets/images/dummy_user.png";
import our_blogs_img1 from "../../../assets/images/our_blogs_img1.png";
import our_blogs_img2 from "../../../assets/images/our_blogs_img2.png";
import our_blogs_img3 from "../../../assets/images/our_blogs_img3.png";

import axios from "axios";
import { toast } from "react-toastify";
import renderHTML from "react-render-html";

import ComingSoonComponent from "../../components/coming_soon";

function AboutViewMoreToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <NavLink type="button" onClick={decoratedOnClick}>
      {isCurrentEventKey ? "Read Less" : "Read More"}
    </NavLink>
  );
}

function HomeComponent() {
  const [allJobs, setAllJobs] = useState([]);
  const [page_no, setPageNo] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [allBlogs, setAllBlogs] = useState([]);
  const [showFullAddress, setShowFullAddress] = useState(false);

  var counter = 0;
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    axios
      .get(
        `https://api.prorider.in/api/v1/web/job-list?page_no=${page_no}&per_page=6`
      )

      .then((resp) => {
        setIsLoading(true);
        // console.log("resp", resp);
        setAllJobs(resp.data.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        //   toast.error("Something went wrong!");
      });

    //   Get Blogs API
    await axios
      .get("https://api.prorider.in/api/v1/blogs/list")
      .then((resp) => {
        // console.log(resp);
        setIsLoading(false);
        setAllBlogs(resp.data.data.splice(0, 3));
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Something went wrong!");
      });
  };
  //   console.log("data-----------", allJobs);
  const TestimoniOwlCarousel = {
    margin: 30,
    responsiveClass: true,
    // nav: true,
    dots: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1200: {
        items: 3,
      },
    },
  };

  return (
    <>
      {/* banner Start here */}
      <section className="banner">
        <Image width="100%" src={home_banner1} alt="image" />
        <div className="banner_content_area">
          <Container>
            <Row className="justify-content-end">
              <Col md={7} lg={5}>
                <div className="banner_content ms-lg-4">
                  <h2>Find Your Perfect Delivery Job Today!</h2>
                  <p>
                    ProRider - Delivery Jobwala connects you with top delivery
                    companies in your city. Simple, fast, and free.
                  </p>
                  <NavLink to="/post-a-job">
                    <Button
                      variant="outline-warning"
                      size="md"
                      className="my-1"
                    >
                      Post A Job
                    </Button>
                  </NavLink>
                  <NavLink to="/jobs">
                    <Button
                      variant="outline-warning"
                      size="md"
                      className="ms-1 ms-md-3 my-1"
                    >
                      Find A Job
                      <svg
                        className="ms-2"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.125 0C3.65234 0 0 3.65234 0 8.125C0 12.5977 3.65234 16.25 8.125 16.25C9.91211 16.25 11.5674 15.6641 12.9102 14.6777L17.8418 19.6094C19.0381 20.7813 20.7959 18.9844 19.5996 17.8125L14.6875 12.9004C15.6689 11.5576 16.25 9.90723 16.25 8.125C16.25 3.65234 12.5977 0 8.125 0ZM8.125 2.5C11.2451 2.5 13.75 5.00488 13.75 8.125C13.75 11.2451 11.2451 13.75 8.125 13.75C5.00488 13.75 2.5 11.2451 2.5 8.125C2.5 5.00488 5.00488 2.5 8.125 2.5Z"
                          fill="#FDC648"
                        />
                      </svg>
                    </Button>
                  </NavLink>
                  <NavLink to="post-a-job">
                    <Button
                      variant="outline-warning"
                      size="md"
                      className="my-1 ms-md-3"
                    >
                      Join As A Vendor
                    </Button>
                  </NavLink>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {/*  banner End here */}

      {/* key benefits Start here */}

      <section className="secton_padding">
        <div className="">
          <Container>
            <Row className="justify-content-center">
              <Col md="5">
                <div className="text-center hdng_title">
                  <h4 className="title_text">
                    Why To Choose ProRider Delivery Jobwala
                  </h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div className="benefits_card">
                  <span className="benefits_icon">
                    <img src={potential_icon} alt="image" />
                  </span>
                  <div className="text-end">
                    <img className="dgt_bk" src={dgt1} alt="image" />
                  </div>
                  <h3>One Super App</h3>
                  <p>Apply for all the delivery jobs for a single app</p>
                </div>
              </Col>
              <Col md="4">
                <div className="benefits_card">
                  <span className="benefits_icon">
                    <img src={eye_icon} alt="image" />
                  </span>
                  <div className="text-end">
                    <img className="dgt_bk" src={dgt2} alt="image" />
                  </div>
                  <h3>Explore All Promo Offer</h3>
                  <p>
                    Get access to more than 100 last mile delivery clients
                    promos directly
                  </p>
                </div>
              </Col>
              <Col md="4">
                <div className="benefits_card">
                  <span className="benefits_icon">
                    <img src={diamond_icon} alt="image" />
                  </span>
                  <div className="text-end">
                    <img className="dgt_bk" src={dgt3} alt="image" />
                  </div>
                  <h3>Earn Through Referral</h3>
                  <p>
                    Unlock rewards for your rider network on each successful
                    referral
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {/* key benefits End here */}

      {/* How It Works Start here */}
      <section className="how_it_works secton_padding">
        <Container className="">
          <div className="text-center hdng_title">
            <h4 className="title_text">How It Works</h4>
            <p>Simple, step-by-step visual guide:</p>
          </div>
          <Row className="align-items-center">
            <Col sm="6" lg={7}>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Step 1</Accordion.Header>
                  <Accordion.Body>
                    <p>Install app and sign up</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Step 2</Accordion.Header>
                  <Accordion.Body>
                    Do all the verifications to get access to all jobs
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Step 3</Accordion.Header>
                  <Accordion.Body>
                    Apply to the nearby job offers and choose the highest income
                    from the promo
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col sm="6" lg={5}>
              <div className="mt-3">
                <Image fluid src={how_it_works} alt="image" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* How It Works End here */}

      {/* latest job Start here */}
      <section className="latest_job secton_padding">
        <Container className="">
          <Row className="align-items-center">
            <Col>
              <div className="hdng_title">
                <h4 className="title_text m-0">latest jobs</h4>
              </div>
            </Col>
            <Col sm="auto">
              <NavLink className="anchor_link" to="/jobs">
                See All
              </NavLink>
            </Col>
          </Row>

          <Row className="g-3 mt-3">
            {allJobs && allJobs.length > 0
              ? allJobs.map((job, index) => {
                  if (counter < 6) {
                    counter = index + 1;
                    return (
                      <Col sm={6} lg={4}>
                        <div className="latest_job_card">
                          {/* <Badge bg="success">New</Badge> */}
                          <div className="latest_job_header">
                            <Row className="g-2">
                              <Col sm="auto">
                                <img
                                  src={job.company_logo}
                                  style={{
                                    borderRadius: "50%",
                                    height: "40px",
                                    width: "40px",
                                  }}
                                  alt="image"
                                />
                              </Col>
                              <Col>
                                <p>{job.job_profile}</p>
                                <small>{job.company_name}</small>
                              </Col>
                            </Row>
                          </div>
                          <div className="latest_job_body mt-2">
                            <Row className="g-1 gx-2">
                              {/* <Col sm="6">
                                <div className="d-flex align-items-start mb-2">
                                  <img
                                    width={25}
                                    className=""
                                    src={rup_rnd}
                                    alt="image"
                                  />
                                  <p>
                                    {job.earnUpto}
                                  </p>
                                </div>

                                <div
                                  className="d-flex align-items-start mb-2"
                                  style={{ height: "50px" }}
                                >
                                  <img
                                    width={25}
                                    className=""
                                    src={map_yellow}
                                    alt="image"
                                  />
                                  <p onClick={()=>{
                                    if(job.hubname.length <= 20){
                                      setShowFullAddress()
                                    }
                                  }}  >
                                    {job.hubname.length <= 20
                                      ? job.hubname+", "
                                      : job.hubname.substring(0, 10) + " ...Read More "}
                                     {job.city_name}
                                  </p>
                                </div>
                              </Col> */}

                              <Col
                                sm="6"
                                style={{
                                  transition: "all 0.3s ease",
                                  height: showFullAddress ? "auto" : "100px", // Adjust size when 'Read More' is clicked
                                  overflow: "hidden", // Prevent overflow when collapsed
                                }}
                              >
                                <div className="d-flex align-items-start mb-2">
                                  <img
                                    width={25}
                                    className=""
                                    src={rup_rnd}
                                    alt="image"
                                  />
                                  <p>{job.earnUpto}</p>
                                </div>

                                <div className="d-flex align-items-start mb-2">
                                  <img
                                    width={25}
                                    className=""
                                    src={map_yellow}
                                    alt="image"
                                  />
                                  <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      setShowFullAddress(!showFullAddress)
                                    }
                                  >
                                    {showFullAddress ? (
                                      `${job.hubname}, ${job.city_name}`
                                    ) : (
                                      <>
                                        {job.hubname.substring(0, 20)}
                                        <span style={{ color: "#fdc648" }}>
                                          ... Read More
                                        </span>
                                      </>
                                    )}
                                  </p>
                                </div>
                              </Col>

                              <Col sm="6">
                                <div className="d-flex align-items-start mb-2">
                                  <img
                                    width={25}
                                    className=""
                                    src={bonus}
                                    alt="image"
                                  />
                                  <p>
                                    Joining Bonus:{" "}
                                    {job.joining_bonus == "0"
                                      ? "NA"
                                      : `Rs.${job.joining_bonus}`}
                                    {/* {job.ev_on_rent == "yes"
                                      ? "EV Bike"
                                      : job.bike_required == "yes"
                                      ? "Petrol Bike"
                                      : "Not Available"} */}
                                  </p>
                                </div>

                                <div className="d-flex align-items-start mb-2">
                                  <img
                                    width={25}
                                    className=""
                                    src={vacancies}
                                    alt="image"
                                  />
                                  <p>{job.vacancy} Vacancies</p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="latest_job_footer mt-2">
                            <Row className="g-1 align-items-center">
                              <Col sm="12" className="text-center">
                                <div className="mt-2 d-flex align-items-center justify-content-between">
                                  <p className="m-0">
                                    Posted On {job.posted_date}
                                  </p>
                                  <NavLink
                                    target="_blank"
                                    to="https://play.google.com/store/apps/details?id=com.prorider"
                                  >
                                    <Button variant="warning" className="px-3">
                                      Apply
                                    </Button>
                                  </NavLink>
                                </div>
                              </Col>
                              <Col>
                                <Row className="g-2">
                                  {/* <Col xs="6">
                                    <div className="d-grid">
                                      <Button size="sm" variant="secondary">
                                        Save
                                        <svg
                                          className="ms-2"
                                          width="12"
                                          height="15"
                                          viewBox="0 0 12 15"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M6.27459 11.3173L5.90699 10.9497L5.53939 11.3173L2.398 14.4587C1.73627 14.3615 1.22818 13.7914 1.22818 13.1027V2.70537C1.22818 1.9485 1.84187 1.33481 2.59874 1.33481H9.21524C9.97211 1.33481 10.5858 1.9485 10.5858 2.70537V13.1027C10.5858 13.7914 10.0777 14.3615 9.41597 14.4587L6.27459 11.3173Z"
                                            stroke="#424141"
                                            stroke-width="1.03974"
                                          />
                                        </svg>
                                      </Button>
                                    </div>
                                  </Col> */}
                                  {/* <Col xs="6">
                                    <div className="d-grid">
                                        <NavLink to="/coming-soon" className="btn btn-warning btn-sm">
                                            View
                                        </NavLink>
                                    </div>
                                  </Col> */}
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    );
                  }
                })
              : ""}
          </Row>
        </Container>
      </section>
      {/* latest job End here */}

      {/* testimonial Start here */}
      <section className="secton_padding testimoni d-none">
        <Container>
          <Row className="justify-content-center">
            <Col lg="12">
              <div className="text-center hdng_title">
                <h4 className="title_text m-0">About Us</h4>
                <p className="fs-15">
                  {" "}
                  ProRider revolutionizes the delivery job market by providing a
                  one-stop solution for job seekers and delivery companies
                  alike. Our innovative super app streamlines the job search
                  process for riders while empowering delivery companies to
                  efficiently connect with a vast network of skilled and
                  verified professionals. Join us in reshaping the future of
                  delivery services and unlocking limitless potential for growth
                  and success.
                </p>
              </div>
            </Col>
          </Row>
          <Accordion>
            <Row className="my-4 g-3 justify-content-center">
              <Col lg="6">
                <div className="hero_about_card">
                  <div className="hero_about_card_header">
                    <Row className="g-2">
                      <Col sm="auto">
                        <img src={siddharth} />
                      </Col>
                      <Col>
                        <div>
                          <h5>Siddharth Nag</h5>
                          <h5>CEO & Founder</h5>
                          <p className="mb-0">
                            I am a seasoned professional with over 14 years of
                            experience in Startups and multinational
                            corporations, including WorkIndia, Swiggy, Reliance
                            Retail, and Delhivery.{" "}
                          </p>
                          <AboutViewMoreToggle eventKey="0">
                            Read More
                          </AboutViewMoreToggle>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="hero_about_card_body">
                    <Accordion.Collapse eventKey="0">
                      <p>
                        I specialize in Central Hiring and Retention Strategy
                        for the blue-collar workforce, having managed the
                        recruitment of over 400,000 delivery riders across
                        various sectors. My expertise lies in developing and
                        implementing effective strategies to address
                        organizational challenges and streamline operations
                        cost-effectively. Passionate about "India Rising," I aim
                        to harness technology to tackle unemployment and drive
                        inclusive growth, particularly in non-metro areas.
                      </p>
                    </Accordion.Collapse>
                  </div>
                </div>
              </Col>

              <Col lg="6">
                <div className="hero_about_card">
                  <div className="hero_about_card_header">
                    <Row className="g-2">
                      <Col sm="auto">
                        <img src={neehar} />
                      </Col>
                      <Col>
                        <div>
                          <h5>Neehar R</h5>
                          <h5>COO & Co-Founder</h5>
                          <p className="mb-0">
                            A strategic thinker with a proven track record in
                            Marketing, Branding & Insights. My expertise lies in
                            presales, networking & community building, where
                            I've spent the last few years leading initiatives
                            that drive growth and efficiency of multiple
                            startups.{" "}
                          </p>
                          <AboutViewMoreToggle eventKey="1">
                            Read More
                          </AboutViewMoreToggle>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="hero_about_card_body">
                    <Accordion.Collapse eventKey="1">
                      <p>
                        My approach is rooted in data-driven decision-making and
                        a deep understanding of market dynamics. With ProRider,
                        we aim to disrupt LAST MILE DELIVERY - MANPOWER SOURCING
                        by implementing innovative tech solutions that are both{" "}
                      </p>
                    </Accordion.Collapse>
                  </div>
                </div>
              </Col>
            </Row>
          </Accordion>

          <div className="text-center">
            <NavLink to="about-us" className="btn btn-lg view_more_btn">
              View More
            </NavLink>
          </div>

          {/* <Row>
            <Col lg="12">
              <OwlCarousel className="mt-3 owl-theme" {...TestimoniOwlCarousel}>
                <div>
                  <div className="testimoni_card">
                    <div className="d-flex justify-content-between">
                      <img className="user_img" src={dummy_user} alt="image" />
                      <div>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-half"></i>
                        <i className="bi bi-star "></i>
                      </div>
                    </div>
                    <div className="testimoni_content">
                      <h4>Floyd Miles</h4>
                      <p>
                        Amet minim mollit non deserunt ullamco est sit aliqua
                        dolor do amet sint. Velit officia consequat duis enim
                        velit mollit. Exercitation veniam consequat sunt nostrud
                        amet. Amet minim mollit non deserunt ullamco est sit
                        aliqua dolor do amet sint. Velit officia consequat duis
                        enim velit mollit. Exercitation veniam consequat sunt
                        nostrud amet.
                      </p>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="testimoni_card">
                    <div className="d-flex justify-content-between">
                      <img className="user_img" src={dummy_user} alt="image" />
                      <div>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-half"></i>
                        <i className="bi bi-star "></i>
                      </div>
                    </div>
                    <div className="testimoni_content">
                      <h4>Floyd Miles</h4>
                      <p>
                        Amet minim mollit non deserunt ullamco est sit aliqua
                        dolor do amet sint. Velit officia consequat duis enim
                        velit mollit. Exercitation veniam consequat sunt nostrud
                        amet. Amet minim mollit non deserunt ullamco est sit
                        aliqua dolor do amet sint. Velit officia consequat duis
                        enim velit mollit. Exercitation veniam consequat sunt
                        nostrud amet.
                      </p>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="testimoni_card">
                    <div className="d-flex justify-content-between">
                      <img className="user_img" src={dummy_user} alt="image" />
                      <div>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-half"></i>
                        <i className="bi bi-star "></i>
                      </div>
                    </div>
                    <div className="testimoni_content">
                      <h4>Floyd Miles</h4>
                      <p>
                        Amet minim mollit non deserunt ullamco est sit aliqua
                        dolor do amet sint. Velit officia consequat duis enim
                        velit mollit. Exercitation veniam consequat sunt nostrud
                        amet. Amet minim mollit non deserunt ullamco est sit
                        aliqua dolor do amet sint. Velit officia consequat duis
                        enim velit mollit. Exercitation veniam consequat sunt
                        nostrud amet.
                      </p>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="testimoni_card">
                    <div className="d-flex justify-content-between">
                      <img className="user_img" src={dummy_user} alt="image" />
                      <div>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-half"></i>
                        <i className="bi bi-star "></i>
                      </div>
                    </div>
                    <div className="testimoni_content">
                      <h4>Floyd Miles</h4>
                      <p>
                        Amet minim mollit non deserunt ullamco est sit aliqua
                        dolor do amet sint. Velit officia consequat duis enim
                        velit mollit. Exercitation veniam consequat sunt nostrud
                        amet. Amet minim mollit non deserunt ullamco est sit
                        aliqua dolor do amet sint. Velit officia consequat duis
                        enim velit mollit. Exercitation veniam consequat sunt
                        nostrud amet.
                      </p>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </Col>
          </Row> */}
        </Container>
      </section>
      {/* testimonial End here */}

      {/* Our Blogs Start here */}
      <section className="our_blogs secton_padding">
        <Container className="">
          <Row className="align-items-center">
            <Col>
              <div className="hdng_title">
                <h4 className="title_text m-0">Industry Updates And News</h4>
                <p>Delivery Pro Tips & Industry Insights</p>
              </div>
            </Col>
            <Col sm="auto">
              <NavLink className="anchor_link" to="/blogs">
                See All
              </NavLink>
            </Col>
          </Row>

          <Row className="g-3 mt-3">
            {isLoading === false &&
              allBlogs.length > 0 &&
              allBlogs.map((blog) => (
                <Col sm="6" lg="4">
                  <div className="our_blogs_card">
                    <div className="our_blogs_card_hdr">
                      <NavLink to={"/blog-details-1/" + blog?.id}>
                        <img
                          src={
                            blog.image !== null ? blog.image : our_blogs_img1
                            // our_blogs_img1
                          }
                          alt="image"
                          style={{
                            height: "200px",
                            objectFit: "cover",
                          }}
                        />
                      </NavLink>
                    </div>
                    <div className="our_blogs_card_body">
                      <h5>{blog?.title}</h5>

                      <p>
                        {renderHTML(
                          blog?.content.length > 40
                            ? blog.content.substring(0, 120) + "..."
                            : blog.content
                        )}
                      </p>
                      <div className="d-flex align-items-center justify-content-between">
                        <small> {blog.blog_date}</small>
                        <NavLink to={"/blog-details-1/" + blog?.id}>
                          Read More
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>

          {/* <Row className="g-3 mt-3">
                        
                    </Row> */}
        </Container>
      </section>
      {/* Our Blogs End here */}

      {/* Coming Soon Start here */}
      <ComingSoonComponent />
      {/* Coming Soon End here */}
    </>
  );
}

export default HomeComponent;
