import React from "react";
import { Button, Col, Container,Form,Nav, Row } from "react-bootstrap";

import our_blogs_img1 from '../../../assets/images/our_blogs_img1.png';
import our_blogs_img2 from '../../../assets/images/our_blogs_img2.png';
import our_blogs_img3 from '../../../assets/images/our_blogs_img3.png';
import { NavLink } from "react-router-dom";

function BlogDetails_3Component(){
    return(
        <>




        <section className="secton_padding">
            <Container>
                <Row className="">
                    <Col lg="8" md="7" sm="12">
                        <img width="100%" src={our_blogs_img3} alt="image" />
                        <div className="mt-3">
                            <h4 className="fs-25 fw-600">Navigating the Blue Collar Boom: The Quick Commerce Revolution</h4>
                            <h6 className="fs-16 fw-400">In the ever-evolving realm of quick commerce, where speed reigns supreme and convenience is king, the demand for blue-collar manpower has become more than just a necessity—it's a battleground for success. Let's take a closer look at how India's leading quick commerce players are tackling the dynamic interplay of demand and supply in the blue-collar workforce, with real-time examples and intriguing facts.</h6>
                            <h6 className="fs-16 fw-400">Quick commerce, or Q-commerce, has taken India by storm, revolutionizing the way we shop for essentials. With players like Flipkart's Quick, Amazon's Prime Now, and Swiggy's Instamart, consumers can now order groceries, electronics, and more at the tap of a button, with lightning-fast deliveries promised within hours.</h6>
                        </div>
                        
                        <div className="mt-3">
                            <h6 className="fs-20 fw-600 mb-1">The Blue-Collar Backbone: A Reality Check</h6>
                            <p>Behind the scenes, however, lies a workforce facing immense challenges. Take Zomato, for instance. With over 1.5 lakh delivery partners across India, the company is constantly navigating the delicate balance between meeting surging demand and ensuring the well-being of its frontline workers.</p>
                        </div>
                        
                        <div className="mt-3">
                            <h6 className="fs-20 fw-600 mb-1">Demand Surges, Supply Struggles: A Look at the Numbers</h6>
                            <p>According to recent reports, India's quick commerce market is projected to reach a staggering valuation of $5 billion by 2025, driven by a compound annual growth rate of over 40%. However, this rapid expansion comes with its own set of challenges, particularly in the realm of blue-collar manpower.</p>
                        </div>
                        
                        <div className="mt-3">
                            <h6 className="fs-20 fw-600 mb-1">Attrition Woes and Retention Realities</h6>
                            <p>Swiggy, one of India's pioneers in the quick commerce space, grapples with attrition rates hovering around 30-35% among its delivery partners. To combat this, the company has rolled out innovative initiatives like Swiggy Hunger Savior Fund, offering financial assistance to delivery executives during emergencies, thereby fostering loyalty and reducing turnover.</p>
                        </div>
                        
                        <div className="mt-3">
                            <h6 className="fs-20 fw-600 mb-1">Upskilling for Success: Dunzo's Approach</h6>
                            <p>Dunzo, often hailed as the "super app" for its multifaceted services, understands the importance of upskilling its workforce. With initiatives like Dunzo Varsity, the company provides comprehensive training modules to equip its delivery partners with essential skills, from effective communication to efficient route planning.</p>
                        </div>
                        
                        <div className="mt-3">
                            <h6 className="fs-20 fw-600 mb-1">Tech Tales and Operational Optimization</h6>
                            <p>Tech-driven solutions play a pivotal role in streamlining operations and enhancing efficiency. Take Dunzo's AI-powered Dynamic Rider Allocation system, for example. By leveraging machine learning algorithms, the platform optimizes delivery routes in real-time, minimizing travel time and maximizing productivity for its fleet of delivery partners.</p>
                        </div>
                        
                        <div className="mt-3">
                            <h6 className="fs-20 fw-600 mb-1">Higher education-based support by Big Basket</h6>
                            <p>As India's quick commerce landscape continues to evolve, collaboration and innovation will be key drivers of success. From forging partnerships with vocational training institutes to embracing cutting-edge technologies, companies are leaving no stone unturned in their quest to meet the burgeoning demand for blue-collar manpower while ensuring the well-being and satisfaction of their workforce by providing them learn while earn opportunities.</p>
                        
                            <p>In the dynamic world of quick commerce, the demand for blue-collar manpower shows no signs of slowing down. As companies navigate the intricate dance between supply and demand, real-time examples from industry leaders offer valuable insights into the strategies and initiatives driving success in this burgeoning sector. </p>

                            <p>With innovation, collaboration, and a steadfast commitment to employee welfare, India's quick commerce titans are poised to conquer new frontiers and reshape the future of retail in the digital age.</p>
                        </div>
                        
                        <Row className="mt-4">
                            <Col>
                                <NavLink>
                                    <Button className="px-3" variant="secondary" size="md">Previous Blog</Button>
                                </NavLink>
                            </Col>
                            <Col xs="auto">
                                <NavLink>
                                    <Button className="px-3" variant="secondary" size="md">Next Blog</Button>
                                </NavLink>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="4" md="5" sm="12">
                        <div className="share_links">
                            <h4>Share</h4>
                            <div>
                                <Nav className="justify-content-center">
                                    <Nav.Item>
                                        <NavLink to="/">
                                            <span>
                                                <i className="bi bi-facebook"></i>
                                            </span>
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <NavLink to="/">
                                            <span>
                                                <i className="bi bi-linkedin"></i>
                                            </span>
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <NavLink to="/">
                                            <span>
                                                <i className="bi bi-twitter-x"></i>
                                            </span>
                                        </NavLink>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                        <hr className="my-4" />


                        <div className="hdng_title">
                            <h4 className="fs-25 fw-600 m-0">Latest Blogs</h4>
                        </div>
                        <Row className="g-3">
                            <Col sm="12">
                                <div className="our_blogs_card mt-3">
                                    <div className="our_blogs_card_hdr">
                                        <NavLink to="/blog-details-1">
                                            <img src={our_blogs_img1} alt="image" />
                                        </NavLink>
                                    </div>
                                    <div className="our_blogs_card_body">
                                        <h5>Understanding the Demography of Delivery Boys in India</h5>
                                        
                                        <p>In recent years, the rise of e-commerce platforms and food delivery services has significantly transformed the way goods and</p>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <small>19 Jan 2022</small>
                                            <NavLink to="/blog-details-1">Read More</NavLink>
                                        </div>
                                        
                                    </div>
                                </div>
                            </Col>
                            <Col sm="12">
                                <div className="our_blogs_card mt-3">
                                    <div className="our_blogs_card_hdr">
                                        <NavLink to="/blog-details-2">
                                            <img src={our_blogs_img2} alt="image" />
                                        </NavLink>
                                    </div>
                                    <div className="our_blogs_card_body">
                                        <h5>Exploring Last Mile Delivery Job Options in India</h5>
                                        
                                        <p>In recent years, the e-commerce boom has significantly transformed the retail landscape in India. With the rise of online shopping platforms, </p>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <small>19 Jan 2022</small>
                                            <NavLink to="/blog-details-2">Read More</NavLink>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="12">
                                <div className="our_blogs_card mt-3">
                                    <div className="our_blogs_card_hdr">
                                        <NavLink to="/blog-details-3">
                                            <img src={our_blogs_img3} alt="image" />
                                        </NavLink>
                                    </div>
                                    <div className="our_blogs_card_body">
                                        <h5>Navigating the Blue Collar Boom: The Quick Commerce Revolution</h5>
                                        
                                        <p>In the ever-evolving realm of quick commerce, where speed reigns supreme and convenience is king, the demand for blue-collar manpower has </p>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <small>19 Jan 2022</small>
                                            <NavLink to="/blog-details-3">Read More</NavLink>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                
                {/* <Row className="align-items-center justify-content-center">
                    <Col lg="9">
                        <img width="100%" src={our_blogs_img3} alt="image" />
                    </Col>
                </Row>
                <Row className="align-items-center justify-content-center">
                    <Col sm="12">
                        <div className=" mt-3">
                            <h6 className="fs-20 fw-600 mb-1">1. Choose Your Jobs Wisely:</h6>

                            <ul className="ps-3">
                                <li className="fs-16 fw-400"><b>Pay Attention to Rates:</b> Not all delivery jobs are created equal. Some companies or platforms offer higher base rates per delivery, while others rely more on tipping. Look for jobs with competitive pay structures that factor in factors like distance and delivery time.</li>
                                <li className="fs-16 fw-400"><b>Consider Incentives and Bonuses:</b> Many companies offer bonuses for completing a certain number of deliveries in a set timeframe or during peak hours. Take advantage of these opportunities to boost your income.</li>
                                <li className="fs-16 fw-400"><b>Short vs. Long Deliveries:</b> While short deliveries might seem quicker, they may not always translate to higher earnings per hour. Consider a mix of short and long distances, depending on the pay structure and time efficiency in your area (e.g., traffic patterns).</li>
                            </ul>
                        </div>
                        <div className=" mt-3">
                            <h6 className="fs-20 fw-600 mb-1">1. Choose Your Jobs Wisely:</h6>

                            <ul className="ps-3">
                                <li className="fs-16 fw-400"><b>Pay Attention to Rates:</b> Not all delivery jobs are created equal. Some companies or platforms offer higher base rates per delivery, while others rely more on tipping. Look for jobs with competitive pay structures that factor in factors like distance and delivery time.</li>
                                <li className="fs-16 fw-400"><b>Consider Incentives and Bonuses:</b> Many companies offer bonuses for completing a certain number of deliveries in a set timeframe or during peak hours. Take advantage of these opportunities to boost your income.</li>
                                <li className="fs-16 fw-400"><b>Short vs. Long Deliveries:</b> While short deliveries might seem quicker, they may not always translate to higher earnings per hour. Consider a mix of short and long distances, depending on the pay structure and time efficiency in your area (e.g., traffic patterns).</li>
                            </ul>
                        </div>
                        <div className=" mt-3">
                            <h6 className="fs-20 fw-600 mb-1">1. Choose Your Jobs Wisely:</h6>

                            <ul className="ps-3">
                                <li className="fs-16 fw-400"><b>Pay Attention to Rates:</b> Not all delivery jobs are created equal. Some companies or platforms offer higher base rates per delivery, while others rely more on tipping. Look for jobs with competitive pay structures that factor in factors like distance and delivery time.</li>
                                <li className="fs-16 fw-400"><b>Consider Incentives and Bonuses:</b> Many companies offer bonuses for completing a certain number of deliveries in a set timeframe or during peak hours. Take advantage of these opportunities to boost your income.</li>
                                <li className="fs-16 fw-400"><b>Short vs. Long Deliveries:</b> While short deliveries might seem quicker, they may not always translate to higher earnings per hour. Consider a mix of short and long distances, depending on the pay structure and time efficiency in your area (e.g., traffic patterns).</li>
                            </ul>
                        </div>
                        <div className=" mt-3">
                            <h6 className="fs-20 fw-600 mb-1">1. Choose Your Jobs Wisely:</h6>

                            <ul className="ps-3">
                                <li className="fs-16 fw-400"><b>Pay Attention to Rates:</b> Not all delivery jobs are created equal. Some companies or platforms offer higher base rates per delivery, while others rely more on tipping. Look for jobs with competitive pay structures that factor in factors like distance and delivery time.</li>
                                <li className="fs-16 fw-400"><b>Consider Incentives and Bonuses:</b> Many companies offer bonuses for completing a certain number of deliveries in a set timeframe or during peak hours. Take advantage of these opportunities to boost your income.</li>
                                <li className="fs-16 fw-400"><b>Short vs. Long Deliveries:</b> While short deliveries might seem quicker, they may not always translate to higher earnings per hour. Consider a mix of short and long distances, depending on the pay structure and time efficiency in your area (e.g., traffic patterns).</li>
                            </ul>
                        </div>
                    </Col>
                </Row> */}
            </Container>
        </section>


        {/* <section className="pb-5">
            <Container>
                <Row className="">
                    <Col sm="8">
                        <h5 className="fs-16 fw-700">Comments</h5>
                        <div className="comments_card">
                            <Row className="g-2">
                                <Col sm="12">
                                    <p>thanks for putting this out there!</p>
                                    <h6>Michael Ferrara</h6>
                                </Col>
                                <Col sm="12">
                                    <p>thanks for putting this out there!</p>
                                    <h6>Michael Ferrara</h6>
                                </Col>
                                <Col sm="12">
                                    <p>thanks for putting this out there!</p>
                                    <h6>Michael Ferrara</h6>
                                </Col>
                                <Col sm="12">
                                    <p>thanks for putting this out there!</p>
                                    <h6>Michael Ferrara</h6>
                                </Col>
                                <Col sm="12">
                                    <Form>
                                        <Form.Group className="mb-3" controlId="">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control as="textarea" rows={2}  placeholder="Write something..."  />
                                        </Form.Group>
                                        <Button variant="light" size="md" type="submit">Submit</Button>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section> */}
        
        </>
    )
}

export default BlogDetails_3Component;