import React from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logo_wht from "../../../assets/images/logo_wht.png";

function FooterComponent() {
  return (
    <>
      <footer>
        <Container>
          <Row className="">
            <Col lg="4" sm="6">
              <div className="ftr_cntnt">
                <img className="brand_" src={logo_wht} alt="image" />
                <div className="follow_us">
                  <h5>Follow Us</h5>
                  <Nav>
                    <Nav.Item>
                      <NavLink
                        target="_blank"
                        to="https://www.instagram.com/prorider_delivery_jobwala?igsh=dDB5NDNyeWVyb3N6"
                      >
                        <span>
                          <i className="bi bi-instagram"></i>
                        </span>
                      </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                      <NavLink
                        target="_blank"
                        to="https://www.facebook.com/profile.php?id=61558656586870"
                      >
                        <span>
                          <i className="bi bi-facebook"></i>
                        </span>
                      </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                      <NavLink
                        target="_blank"
                        to="https://www.linkedin.com/company/prorider-delivery-jobwala/"
                      >
                        <span>
                          <i className="bi bi-linkedin"></i>
                        </span>
                      </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                      <NavLink
                        target="_blank"
                        to="http://www.youtube.com/@ProRider-DeliveryJobwala"
                      >
                        <span>
                          <i className="bi bi-youtube"></i>
                        </span>
                      </NavLink>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
            </Col>

            <Col className="order-lg-last" sm="6" lg="2">
              <div className="ftr_menu">
                <h4>Contact Us</h4>
                <ul>
                  <li>
                    <NavLink to="tel:+91 98346 31715">
                      <span className="me-2">
                        <i className="bi bi-telephone-fill"></i>
                      </span>
                      <span>
                        +91 98346 31715 &nbsp; &nbsp;&nbsp;&nbsp; +91 80500
                        83233
                      </span>
                      {/* <span></span> */}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="mailto:ask@prorider.in">
                      <span className="me-2">
                        <i className="bi bi-envelope"></i>
                      </span>
                      <span style={{ textTransform: "lowercase" }}>
                        ask@prorider.in
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink>
                      <span className="me-2">
                        <i className="bi bi-geo-alt"></i>
                      </span>
                      <span style={{ fontSize: "16px" }}>
                        771, Kailash <br />
                        &nbsp;&nbsp;&nbsp; Enclave, Shivpuri, &nbsp;
                        {/* &nbsp;&nbsp;&nbsp; <br /> */}
                        &nbsp;&nbsp;&nbsp;Boring Road, <br />
                        &nbsp;&nbsp;&nbsp; Patna 800013
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg="6" sm="12">
              <Row className="">
                <Col sm="3" lg="4">
                  <div className="ftr_menu">
                    <h4>Company</h4>
                    <ul>
                      <li>
                        <NavLink to="/">Home</NavLink>
                      </li>
                      <li>
                        <NavLink to="about-us">About us</NavLink>
                      </li>
                      <li>
                        <NavLink to="jobs">jobs</NavLink>
                      </li>
                      <li>
                        <NavLink to="blogs">Blogs</NavLink>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col sm="3" lg="4">
                  <div className="ftr_menu">
                    <h4>For Employer</h4>
                    <ul>
                      <li>
                        <NavLink to="post-a-job">Post A Job</NavLink>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col sm="3" lg="4">
                  <div className="ftr_menu">
                    <h4>Legal</h4>
                    <ul>
                      <li>
                        <NavLink to="terms-and-service">
                          Terms & Service
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="privacy-policy">Privacy Policy</NavLink>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="copyright">
            <p>
              2024 ProRiders. All rights reserved , Technologies Partner -{" "}
              <span>
                {" "}
                <NavLink to="https://weboconnect.com" target="_blank">
                  {" "}
                  WeboConnect
                </NavLink>
              </span>{" "}
            </p>
          </div>
        </Container>
      </footer>
    </>
  );
}

export default FooterComponent;
