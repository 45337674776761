import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Nav, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import our_blogs_img1 from "../../../assets/images/our_blogs_img1.png";
import our_blogs_img3 from "../../../assets/images/our_blogs_img3.png";
import axios from "axios";
import renderHTML from "react-render-html";
import { toast } from "react-toastify";

function BlogDetails_1Component() {
  const [blogDetails, setBlogDetails] = useState({});
  const [allBlogs, setAllBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [nextPage, setNextPage] = useState(false);
  const [prevPage, setPrevPage] = useState(false);
  const [nextId, setNextId] = useState(0);
  const [prevId, setPrevId] = useState(0);

  useEffect(() => {
    const id = window.location.pathname.split("/")[2];
    getData(id);
    getAllBlogs();
  }, []);

  const getAllBlogs = async () => {
    await axios
      .get("https://api.prorider.in/api/v1/blogs/list")
      .then((resp) => {
        // console.log(resp);
        setIsLoading(false);
        setAllBlogs(resp.data.data);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Something went wrong!");
      });
  };

  const getData = async (id) => {
    axios
      .post("https://api.prorider.in/api/v1/blogs/blogs/list/" + id)
      .then((resp) => {
        // console.log(resp.data);
        if (resp.data.next_blog.length > 0) {
          setAllBlogs(resp.data.next_blog);
          setNextPage(true);
          setPrevPage(true);
          setNextId(resp.data.next_blog[0].id);
        } else {
          setNextPage(false);
          getAllBlogs()
        }
        if (resp.data.previous_blog.length > 0) {
          setPrevPage(0);
          setPrevId(resp.data.previous_blog[0].id);
          setPrevPage(true);
        } else {
          setPrevPage(false);
        }
        setIsLoading(false);
        setBlogDetails(resp.data.data);
      });
  };

  return (
    <>
      <section className="secton_padding">
        <Container>
          <Row className="">
            <Col lg="8" md="7" sm="12">
              <img
                width="100%"
                src={blogDetails.image ? blogDetails.image : our_blogs_img1}
                alt="image"
              />
              <div className="mt-3">
                <h1> {blogDetails?.title} </h1>
                {/* <div>{renderHTML(blogDetails?.content)}</div> */}
                <div
                  dangerouslySetInnerHTML={{ __html: blogDetails.content }}
                />
              </div>

              <Row className="mt-4">
                <Col>
                  {prevPage === true && (
                    <NavLink
                      to={"/blog-details-1/" + prevId}
                      onClick={() => {
                        getData(prevId);
                      }}
                    >
                      <Button className="px-3" variant="secondary" size="md">
                        Previous Blog
                      </Button>
                    </NavLink>
                  )}
                </Col>
                <Col xs="auto">
                  {nextPage === true && (
                    <NavLink
                      to={"/blog-details-1/" + nextId}
                      onClick={() => {
                        getData(nextId);
                      }}
                    >
                      <Button className="px-3" variant="secondary" size="md">
                        Next Blog
                      </Button>
                    </NavLink>
                  )}
                </Col>
              </Row>
            </Col>
            <Col lg="4" md="5" sm="12">
              <div className="share_links">
                <h4>Share</h4>
                <div>
                  <Nav className="justify-content-center">
                    <Nav.Item>
                      <NavLink
                        to="https://www.facebook.com/sharer/sharer.php?u=https://deliveryjobwala.com/"
                        target="_blank"
                      >
                        <span>
                          <i className="bi bi-facebook"></i>
                        </span>
                      </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                      <NavLink
                        to="https://www.linkedin.com/shareArticle?url=https://deliveryjobwala.com/"
                        target="_blank"
                      >
                        <span>
                          <i className="bi bi-linkedin"></i>
                        </span>
                      </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                      <NavLink
                        to="https://twitter.com/intent/tweet?text=https://deliveryjobwala.com/"
                        target="_blank"
                      >
                        <span>
                          <i className="bi bi-twitter-x"></i>
                        </span>
                      </NavLink>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
              <hr className="my-4" />

              <div className="hdng_title">
                <h4 className="fs-25 fw-600 m-0">Latest Blogs</h4>
              </div>

              <Row className="g-3">
                {isLoading === false &&
                  allBlogs?.length > 0 &&
                  allBlogs.map((blog) => (
                    <Col sm="12">
                      <div className="our_blogs_card_latest mt-3">
                        <div className="our_blogs_card_hdr">
                          <NavLink
                            to={"/blog-details-1/" + blog?.id}
                            // target="_blank"
                          >
                            <img
                              src={blog.image ? blog.image : our_blogs_img3}
                              alt="image"
                              style={{
                                height: "160px",
                                objectFit: "cover",
                              }}
                              onClick={() => {
                                getData(blog?.id);
                              }}
                            />
                          </NavLink>
                        </div>
                        <div className="our_blogs_card_body">
                          <h5>{blog.title}</h5>

                          <p>
                            {renderHTML(
                              blog?.content.length > 40
                                ? blog.content.substring(0, 120) + "..."
                                : blog.content
                            )}
                          </p>
                          <div className="d-flex align-items-center justify-content-between">
                            {/* <small>19 Jan 2022</small> */}
                            <NavLink
                              to="#"
                              onClick={() => {
                                getData(blog?.id);
                              }}
                            >
                              Read More
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>

          {/* <Row className="align-items-center justify-content-center">
                    <Col lg="9">
                        <img width="100%" src={our_blogs_img3} alt="image" />
                    </Col>
                </Row>
                <Row className="align-items-center justify-content-center">
                    <Col sm="12">
                        <div className=" mt-3">
                            <h6 className="fs-20 fw-600 mb-1">1. Choose Your Jobs Wisely:</h6>

                            <ul className="ps-3">
                                <li className="fs-16 fw-400"><b>Pay Attention to Rates:</b> Not all delivery jobs are created equal. Some companies or platforms offer higher base rates per delivery, while others rely more on tipping. Look for jobs with competitive pay structures that factor in factors like distance and delivery time.</li>
                                <li className="fs-16 fw-400"><b>Consider Incentives and Bonuses:</b> Many companies offer bonuses for completing a certain number of deliveries in a set timeframe or during peak hours. Take advantage of these opportunities to boost your income.</li>
                                <li className="fs-16 fw-400"><b>Short vs. Long Deliveries:</b> While short deliveries might seem quicker, they may not always translate to higher earnings per hour. Consider a mix of short and long distances, depending on the pay structure and time efficiency in your area (e.g., traffic patterns).</li>
                            </ul>
                        </div>
                        <div className=" mt-3">
                            <h6 className="fs-20 fw-600 mb-1">1. Choose Your Jobs Wisely:</h6>

                            <ul className="ps-3">
                                <li className="fs-16 fw-400"><b>Pay Attention to Rates:</b> Not all delivery jobs are created equal. Some companies or platforms offer higher base rates per delivery, while others rely more on tipping. Look for jobs with competitive pay structures that factor in factors like distance and delivery time.</li>
                                <li className="fs-16 fw-400"><b>Consider Incentives and Bonuses:</b> Many companies offer bonuses for completing a certain number of deliveries in a set timeframe or during peak hours. Take advantage of these opportunities to boost your income.</li>
                                <li className="fs-16 fw-400"><b>Short vs. Long Deliveries:</b> While short deliveries might seem quicker, they may not always translate to higher earnings per hour. Consider a mix of short and long distances, depending on the pay structure and time efficiency in your area (e.g., traffic patterns).</li>
                            </ul>
                        </div>
                        <div className=" mt-3">
                            <h6 className="fs-20 fw-600 mb-1">1. Choose Your Jobs Wisely:</h6>

                            <ul className="ps-3">
                                <li className="fs-16 fw-400"><b>Pay Attention to Rates:</b> Not all delivery jobs are created equal. Some companies or platforms offer higher base rates per delivery, while others rely more on tipping. Look for jobs with competitive pay structures that factor in factors like distance and delivery time.</li>
                                <li className="fs-16 fw-400"><b>Consider Incentives and Bonuses:</b> Many companies offer bonuses for completing a certain number of deliveries in a set timeframe or during peak hours. Take advantage of these opportunities to boost your income.</li>
                                <li className="fs-16 fw-400"><b>Short vs. Long Deliveries:</b> While short deliveries might seem quicker, they may not always translate to higher earnings per hour. Consider a mix of short and long distances, depending on the pay structure and time efficiency in your area (e.g., traffic patterns).</li>
                            </ul>
                        </div>
                        <div className=" mt-3">
                            <h6 className="fs-20 fw-600 mb-1">1. Choose Your Jobs Wisely:</h6>

                            <ul className="ps-3">
                                <li className="fs-16 fw-400"><b>Pay Attention to Rates:</b> Not all delivery jobs are created equal. Some companies or platforms offer higher base rates per delivery, while others rely more on tipping. Look for jobs with competitive pay structures that factor in factors like distance and delivery time.</li>
                                <li className="fs-16 fw-400"><b>Consider Incentives and Bonuses:</b> Many companies offer bonuses for completing a certain number of deliveries in a set timeframe or during peak hours. Take advantage of these opportunities to boost your income.</li>
                                <li className="fs-16 fw-400"><b>Short vs. Long Deliveries:</b> While short deliveries might seem quicker, they may not always translate to higher earnings per hour. Consider a mix of short and long distances, depending on the pay structure and time efficiency in your area (e.g., traffic patterns).</li>
                            </ul>
                        </div>
                    </Col>
                </Row> */}
        </Container>
      </section>

      {/* <section className="pb-5">
            <Container>
                <Row className="">
                    <Col sm="8">
                        <h5 className="fs-16 fw-700">Comments</h5>
                        <div className="comments_card">
                            <Row className="g-2">
                                <Col sm="12">
                                    <p>thanks for putting this out there!</p>
                                    <h6>Michael Ferrara</h6>
                                </Col>
                                <Col sm="12">
                                    <p>thanks for putting this out there!</p>
                                    <h6>Michael Ferrara</h6>
                                </Col>
                                <Col sm="12">
                                    <p>thanks for putting this out there!</p>
                                    <h6>Michael Ferrara</h6>
                                </Col>
                                <Col sm="12">
                                    <p>thanks for putting this out there!</p>
                                    <h6>Michael Ferrara</h6>
                                </Col>
                                <Col sm="12">
                                    <Form>
                                        <Form.Group className="mb-3" controlId="">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control as="textarea" rows={2}  placeholder="Write something..."  />
                                        </Form.Group>
                                        <Button variant="light" size="md" type="submit">Submit</Button>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section> */}
    </>
  );
}

export default BlogDetails_1Component;
