import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import logo from "../../assets/images/logo.png";
import comin_soon_app from "../../assets/images/comin_soon_app.png";
import playStoreDownload from "../../assets/images/playStoreDownload.png";
import { NavLink } from "react-router-dom";

function ComingSoonComponent(props) {
  return (
    <>
      <section className="coming_soon">
        <Container className="position-relative">
          <Row className="g-0 align-items-center justify-content-between">
            <Col md="12" lg="7">
              <NavLink
                to="https://play.google.com/store/apps/details?id=com.prorider"
                target="_blank"
                style={{textDecoration: "none"}}
              >
                <div className="coming_soon_card">
                  <img src={logo} />

                  <h3>DOWNLOAD THE APP NOW </h3>
                  <h4>CLICK HERE</h4>
                  <div className="mt-4">
                    <NavLink
                      to="https://play.google.com/store/apps/details?id=com.prorider"
                      target="_blank"
                    >
                      <img src={playStoreDownload} height="100px" />
                    </NavLink>
                  </div>
                </div>
              </NavLink>
            </Col>
            {/* <Col className="comin_soon_app">
              <img src={comin_soon_app} alt="image" />
            </Col> */}
            <Col className="lg-8">
              <img src={comin_soon_app} alt="image" />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default ComingSoonComponent;
