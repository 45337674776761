import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import contact_us from "../../../assets/images/contact_us.png";
import ComingSoonComponent from "../../components/coming_soon";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
function PostJobComponent() {
  const [company_name, setCompanyName] = useState("");
  const [full_name, setFullName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [messagem, setMessage] = useState("");
  const [contact_for, setContactFor] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      company_name: company_name,
      full_name: full_name,
      mobile: mobile,
      email: email,
      description: description,
      contact_for,
    };

    axios
      .post("https://api.prorider.in/api/v1/web/jobPostRequest", payload)
      .then((resp) => {
        // console.log(resp);
        setIsLoading(true);
        setMessage(resp.data.message);
        toast.success("Request submitted!");
        setTimeout(() => {
          setIsLoading(false);
          window.location.assign("/");
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };
  return (
    <>
      <section className="contact_us secton_padding ">
        <div>
          <Container>
            <Row>
              <Col sm="12">
                <div className="hdng_title">
                  <h4 className="title_text">Contact Us</h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="contact_us_card">
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col sm="12">
                        {["radio"].map((type) => (
                          <div
                            key={`conatct-${type}`}
                            className="text-center mb-2"
                          >
                            <Form.Check
                              inline
                              label="Post A Job"
                              name="conatct_us"
                              onChange={(e) => {
                                setContactFor("post_a_job");
                              }}
                              type={type}
                              required
                              id={`conatct-${type}-1`}
                            />
                            <Form.Check
                              inline
                              label="Join As A Vendor"
                              name="conatct_us"
                              type={type}
                              required
                              onChange={(e) => {
                                setContactFor("vendor");
                              }}
                              id={`conatct-${type}-2`}
                            />
                            <Form.Check
                              inline
                              label="Get Database"
                              name="conatct_us"
                              required
                              onChange={(e) => {
                                setContactFor("contact_us");
                              }}
                              type={type}
                              id={`conatct-${type}-3`}
                            />
                          </div>
                        ))}
                      </Col>
                      <hr />
                    </Row>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Company/Vendor Name</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Enter Company Name"
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Contact Person Name</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Enter Contact Person Name"
                        onChange={(e) => {
                          setFullName(e.target.value);
                        }}
                      />
                    </Form.Group>

                    <Row>
                      <Col md="6">
                        <Form.Group className="mb-3" controlId="">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            required
                            placeholder="Enter Email"
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group className="mb-3" controlId="">
                          <Form.Label>Phone No.</Form.Label>
                          <Form.Control
                            type="number"
                            required
                            placeholder="Enter Phone No."
                            value={mobile}
                            onChange={(e) => {
                              if (e.target.value.length <= 10) {
                                setMobile(e.target.value);
                              }
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Whats Your Requirement? </Form.Label>
                      <Form.Control
                        as="textarea"
                        required
                        rows={4}
                        placeholder="Write something..."
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      />
                    </Form.Group>
                    <Button variant="light" size="md" type="submit">
                      Submit
                    </Button>
                  </Form>
                </div>
              </Col>
              <Col md="6">
                <div>
                  <img src={contact_us} alt="image" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {/* Coming Soon Start here */}
      <ComingSoonComponent />
      {/* Coming Soon End here */}
    </>
  );
}

export default PostJobComponent;
