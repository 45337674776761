import React from "react";
import { Outlet } from "react-router-dom";
import HeaderComponent from "../common/header";
import FooterComponent from "../common/footer";

function FrontLayout(){
    return(
        <>
        <HeaderComponent />
            <Outlet />
        <FooterComponent/>
        </>
    )
}

export default FrontLayout;