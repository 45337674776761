import React from "react";
import { Button, Col, Container,Form,Nav, Row } from "react-bootstrap";

import our_blogs_img1 from '../../../assets/images/our_blogs_img1.png';
import our_blogs_img2 from '../../../assets/images/our_blogs_img2.png';
import our_blogs_img3 from '../../../assets/images/our_blogs_img3.png';
import { NavLink } from "react-router-dom";

function BlogDetails_2Component(){
    return(
        <>


        <section className="secton_padding">
            <Container>
                <Row className="">
                    <Col lg="8" md="7" sm="12">
                        <img width="100%" src={our_blogs_img2} alt="image" />
                        <div className="mt-3">
                            <h4 className="fs-25 fw-600">Exploring Last Mile Delivery Job Options in India</h4>
                            <h6 className="fs-16 fw-400">In recent years, the e-commerce boom has significantly transformed the retail landscape in India. With the rise of online shopping platforms, the demand for efficient last mile delivery services has soared. Last mile delivery refers to the final leg of the delivery process, where packages are transported from distribution centers to the customer's doorstep. </h6>
                            <h6 className="fs-16 fw-400">
                            This crucial step often determines customer satisfaction and loyalty, making it a pivotal aspect of the e-commerce ecosystem. In this blog, we'll delve into the various last mile delivery job options in India, explore salary ranges, and highlight some examples with prominent companies.</h6>
                        </div>
                        
                        <div className="mt-3">
                            <h6 className="fs-20 fw-600 mb-1">1. Delivery Executives:</h6>
                            <p>Delivery executives, also known as delivery associates or riders, are responsible for transporting packages from distribution centers to customers' locations. They typically use bikes, scooters, or delivery vans for this purpose. Delivery executives play a vital role in ensuring timely and accurate deliveries.</p>
                            <p><b>Salary Range:</b> The salary range for delivery executives in India varies based on factors such as experience, location, and employer. Entry-level positions may offer salaries ranging from ₹15,000 to ₹25,000 per month, while experienced professionals can earn upwards of ₹30,000 per month.</p>
                            <p><b>Example Companies:</b> Some of the prominent companies offering last mile delivery jobs for delivery executives in India include:</p>
                            <ul>
                                <li>Flipkart</li>
                                <li>Amazon</li>
                                <li>Swiggy</li>
                                <li>Zomato</li>
                                <li>Dunzo</li>
                            </ul>
                        </div>
                        
                        <div className="mt-3">
                            <h6 className="fs-20 fw-600 mb-1">2. Delivery Partners (Freelancers):</h6>
                            <p>Many e-commerce and logistics companies engage delivery partners on a freelance or contract basis to handle last mile deliveries. These delivery partners operate as independent contractors, using their own vehicles for delivery.</p>
                            <p><b>Salary Range:</b> Freelance delivery partners have variable earnings depending on the number of deliveries they complete. On average, they can earn between ₹20,000 to ₹40,000 per month. However, earnings may fluctuate based on factors like order volume, distance traveled, and incentives offered by the platform.</p>
                            <p><b>Example Companies:</b> Some platforms that engage freelance delivery partners in India include:</p>
                            <ul>
                                <li>Dunzo</li>
                                <li>Shadowfax</li>
                                <li>Delhivery</li>
                                <li>Blinkit</li>
                            </ul>
                        </div>
                        
                        <div className="mt-3">
                            <h6 className="fs-20 fw-600 mb-1">3. Courier Delivery Agents:</h6>
                            <p>Courier companies often hire delivery agents to handle parcel deliveries across various locations. These agents are responsible for collecting parcels from the distribution center and delivering them to the designated addresses.</p>
                            <p><b>Salary Range</b>The salary range for courier delivery agents in India can vary based on factors such as company size, location, and experience. Entry-level positions may offer salaries ranging from ₹12,000 to ₹20,000 per month, while experienced agents can earn higher salaries, ranging from ₹25,000 to ₹40,000 per month.</p>
                            <p><b>Example Companies:</b> Some renowned courier companies in India that hire delivery agents include:</p>
                            <ul>
                                <li>Blue Dart</li>
                                <li>DTDC</li>
                                <li>FedEx</li>
                                <li>DHL Express</li>
                            </ul>
                        </div>
                        
                        <div className="mt-3">
                            <h6 className="fs-20 fw-600 mb-1">4. Last Mile Logistics Managers:</h6>
                            <p>Last mile logistics managers oversee the entire last mile delivery process, ensuring smooth operations, timely deliveries, and customer satisfaction. They are responsible for route optimization, driver management, and resolving any delivery-related issues.</p>
                            <p><b>Salary Range</b> Last mile logistics managers typically earn higher salaries compared to delivery executives or partners, with salaries ranging from ₹40,000 to ₹80,000 per month or more, depending on factors such as experience, qualifications, and the size of the organization.</p>
                            <p><b>Example Companies:</b> Some logistics companies and e-commerce giants in India that hire last mile logistics managers include:</p>
                            <ul>
                                <li>Ekart (Flipkart)</li>
                                <li>Amazon Logistics</li>
                                <li>Delhivery</li>
                                <li>Blue Dart</li>
                            </ul>
                            <p> In conclusion, the last mile delivery sector in India offers a wide range of job opportunities catering to diverse skill sets and preferences. Whether you're interested in hands-on delivery roles or managerial positions, there are options available across various companies in the e-commerce and logistics sectors. Additionally, with the continuous growth of online shopping and delivery services, the demand for skilled professionals in last mile delivery is expected to remain robust in the foreseeable future.</p>
                        </div>
                        
                        <Row className="mt-4">
                            <Col>
                                <NavLink>
                                    <Button className="px-3" variant="secondary" size="md">Previous Blog</Button>
                                </NavLink>
                            </Col>
                            <Col xs="auto">
                                <NavLink>
                                    <Button className="px-3" variant="secondary" size="md">Next Blog</Button>
                                </NavLink>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="4" md="5" sm="12">
                        <div className="share_links">
                            <h4>Share</h4>
                            <div>
                                <Nav className="justify-content-center">
                                    <Nav.Item>
                                        <NavLink to="/">
                                            <span>
                                                <i className="bi bi-facebook"></i>
                                            </span>
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <NavLink to="/">
                                            <span>
                                                <i className="bi bi-linkedin"></i>
                                            </span>
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <NavLink to="/">
                                            <span>
                                                <i className="bi bi-twitter-x"></i>
                                            </span>
                                        </NavLink>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                        <hr className="my-4" />


                        <div className="hdng_title">
                            <h4 className="fs-25 fw-600 m-0">Latest Blogs</h4>
                        </div>
                        <Row className="g-3">
                            <Col sm="12">
                                <div className="our_blogs_card mt-3">
                                    <div className="our_blogs_card_hdr">
                                        <NavLink to="/blog-details-1">
                                            <img src={our_blogs_img1} alt="image" />
                                        </NavLink>
                                    </div>
                                    <div className="our_blogs_card_body">
                                        <h5>Understanding the Demography of Delivery Boys in India</h5>
                                        
                                        <p>In recent years, the rise of e-commerce platforms and food delivery services has significantly transformed the way goods and</p>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <small>19 Jan 2022</small>
                                            <NavLink to="/blog-details-1">Read More</NavLink>
                                        </div>
                                        
                                    </div>
                                </div>
                            </Col>
                            <Col sm="12">
                                <div className="our_blogs_card mt-3">
                                    <div className="our_blogs_card_hdr">
                                        <NavLink to="/blog-details-3">
                                            <img src={our_blogs_img3} alt="image" />
                                        </NavLink>
                                    </div>
                                    <div className="our_blogs_card_body">
                                        <h5>Navigating the Blue Collar Boom: The Quick Commerce Revolution</h5>
                                        
                                        <p>In the ever-evolving realm of quick commerce, where speed reigns supreme and convenience is king, the demand for blue-collar manpower has </p>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <small>19 Jan 2022</small>
                                            <NavLink to="/blog-details-3">Read More</NavLink>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="12">
                                <div className="our_blogs_card mt-3">
                                    <div className="our_blogs_card_hdr">
                                        <NavLink to="/blog-details-2">
                                            <img src={our_blogs_img2} alt="image" />
                                        </NavLink>
                                    </div>
                                    <div className="our_blogs_card_body">
                                        <h5>Exploring Last Mile Delivery Job Options in India</h5>
                                        
                                        <p>In recent years, the e-commerce boom has significantly transformed the retail landscape in India. With the rise of online shopping platforms, </p>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <small>19 Jan 2022</small>
                                            <NavLink to="/blog-details-2">Read More</NavLink>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                
                {/* <Row className="align-items-center justify-content-center">
                    <Col lg="9">
                        <img width="100%" src={our_blogs_img3} alt="image" />
                    </Col>
                </Row>
                <Row className="align-items-center justify-content-center">
                    <Col sm="12">
                        <div className=" mt-3">
                            <h6 className="fs-20 fw-600 mb-1">1. Choose Your Jobs Wisely:</h6>

                            <ul className="ps-3">
                                <li className="fs-16 fw-400"><b>Pay Attention to Rates:</b> Not all delivery jobs are created equal. Some companies or platforms offer higher base rates per delivery, while others rely more on tipping. Look for jobs with competitive pay structures that factor in factors like distance and delivery time.</li>
                                <li className="fs-16 fw-400"><b>Consider Incentives and Bonuses:</b> Many companies offer bonuses for completing a certain number of deliveries in a set timeframe or during peak hours. Take advantage of these opportunities to boost your income.</li>
                                <li className="fs-16 fw-400"><b>Short vs. Long Deliveries:</b> While short deliveries might seem quicker, they may not always translate to higher earnings per hour. Consider a mix of short and long distances, depending on the pay structure and time efficiency in your area (e.g., traffic patterns).</li>
                            </ul>
                        </div>
                        <div className=" mt-3">
                            <h6 className="fs-20 fw-600 mb-1">1. Choose Your Jobs Wisely:</h6>

                            <ul className="ps-3">
                                <li className="fs-16 fw-400"><b>Pay Attention to Rates:</b> Not all delivery jobs are created equal. Some companies or platforms offer higher base rates per delivery, while others rely more on tipping. Look for jobs with competitive pay structures that factor in factors like distance and delivery time.</li>
                                <li className="fs-16 fw-400"><b>Consider Incentives and Bonuses:</b> Many companies offer bonuses for completing a certain number of deliveries in a set timeframe or during peak hours. Take advantage of these opportunities to boost your income.</li>
                                <li className="fs-16 fw-400"><b>Short vs. Long Deliveries:</b> While short deliveries might seem quicker, they may not always translate to higher earnings per hour. Consider a mix of short and long distances, depending on the pay structure and time efficiency in your area (e.g., traffic patterns).</li>
                            </ul>
                        </div>
                        <div className=" mt-3">
                            <h6 className="fs-20 fw-600 mb-1">1. Choose Your Jobs Wisely:</h6>

                            <ul className="ps-3">
                                <li className="fs-16 fw-400"><b>Pay Attention to Rates:</b> Not all delivery jobs are created equal. Some companies or platforms offer higher base rates per delivery, while others rely more on tipping. Look for jobs with competitive pay structures that factor in factors like distance and delivery time.</li>
                                <li className="fs-16 fw-400"><b>Consider Incentives and Bonuses:</b> Many companies offer bonuses for completing a certain number of deliveries in a set timeframe or during peak hours. Take advantage of these opportunities to boost your income.</li>
                                <li className="fs-16 fw-400"><b>Short vs. Long Deliveries:</b> While short deliveries might seem quicker, they may not always translate to higher earnings per hour. Consider a mix of short and long distances, depending on the pay structure and time efficiency in your area (e.g., traffic patterns).</li>
                            </ul>
                        </div>
                        <div className=" mt-3">
                            <h6 className="fs-20 fw-600 mb-1">1. Choose Your Jobs Wisely:</h6>

                            <ul className="ps-3">
                                <li className="fs-16 fw-400"><b>Pay Attention to Rates:</b> Not all delivery jobs are created equal. Some companies or platforms offer higher base rates per delivery, while others rely more on tipping. Look for jobs with competitive pay structures that factor in factors like distance and delivery time.</li>
                                <li className="fs-16 fw-400"><b>Consider Incentives and Bonuses:</b> Many companies offer bonuses for completing a certain number of deliveries in a set timeframe or during peak hours. Take advantage of these opportunities to boost your income.</li>
                                <li className="fs-16 fw-400"><b>Short vs. Long Deliveries:</b> While short deliveries might seem quicker, they may not always translate to higher earnings per hour. Consider a mix of short and long distances, depending on the pay structure and time efficiency in your area (e.g., traffic patterns).</li>
                            </ul>
                        </div>
                    </Col>
                </Row> */}
            </Container>
        </section>

        {/* <section className="pb-5">
            <Container>
                <Row className="">
                    <Col sm="8">
                        <h5 className="fs-16 fw-700">Comments</h5>
                        <div className="comments_card">
                            <Row className="g-2">
                                <Col sm="12">
                                    <p>thanks for putting this out there!</p>
                                    <h6>Michael Ferrara</h6>
                                </Col>
                                <Col sm="12">
                                    <p>thanks for putting this out there!</p>
                                    <h6>Michael Ferrara</h6>
                                </Col>
                                <Col sm="12">
                                    <p>thanks for putting this out there!</p>
                                    <h6>Michael Ferrara</h6>
                                </Col>
                                <Col sm="12">
                                    <p>thanks for putting this out there!</p>
                                    <h6>Michael Ferrara</h6>
                                </Col>
                                <Col sm="12">
                                    <Form>
                                        <Form.Group className="mb-3" controlId="">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control as="textarea" rows={2}  placeholder="Write something..."  />
                                        </Form.Group>
                                        <Button variant="light" size="md" type="submit">Submit</Button>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section> */}
        
        </>
    )
}

export default BlogDetails_2Component;