import React from "react";
import ComingSoonComponent from "../../components/coming_soon";
import { Badge, Button, Col, Container, Image, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import siddharth from '../../../assets/images/siddharth.jpg';
import neehar from '../../../assets/images/neehar.jpg';
import gaurav from '../../../assets/images/gaurav.jpg';
import nirbhay from '../../../assets/images/nirbhay.jpg';
import rajiv from '../../../assets/images/rajiv.jpg';

import scooter from '../../../assets/images/icons/add_job_posts.png';
import provider_access from '../../../assets/images/icons/provider_access.png';
import ind_map from '../../../assets/images/icons/ind_map.png';
import promo_offer from '../../../assets/images/icons/offer_section_post.png';
import digital_verify from '../../../assets/images/icons/digital_verify.png';
import targeted_marketing from '../../../assets/images/icons/targeted_marketing.png';
import nationwide_community from '../../../assets/images/icons/nationwide_community.png';



import favicon from '../../../assets/images/icons/favicon.png';
import comin_soon_app_ylw_bg from '../../../assets/images/comin_soon_app_ylw_bg.png';
import about_us_bg from '../../../assets/images/about_us.webp';

function AboutComponent (){
    return(
        <>
            <section className="page_ylw_bnr secton_padding">
                <Container>
                    <Row className="align-items-center justify-content-center">
                        <Col lg="12">
                            <div className="text-center hdng_title">
                                <h4 className="title_text m-0">About Us</h4>
                                <h6>Empowering Delivery Professionals and Businesses</h6>
                                <p>ProRider - revolutionizes the delivery job market by providing a one-stop solution for job seekers and delivery companies alike. Our innovative super app streamlines the job search process for riders while empowering delivery companies to efficiently connect with a vast network of skilled and verified professionals. Join us in reshaping the future of delivery services and unlocking limitless potential for growth and success.</p>
                                <small className="fs-15 fw-600">Current rider requirement - <Badge bg="light" text="dark">2Mn/year</Badge></small>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="about_us_bg">
                <img src={about_us_bg} alt="image"/>
            </section>

            <section className="page_ylw_bnr our_mission_value secton_padding">
                <Container>
                    <Row className="g-3 justify-content-center">
                        <Col sm="12" lg="12">
                            <div className="hdng_title">
                                <div className="text-center align-items-center">
                                    <h4 className="title_text">What does ProRider Offer as solution</h4>
                                </div>
                            </div>
                        </Col>

                        <Col lg="4" sm="6">
                            <div className="solution_card">
                                <img src={scooter} />
                                <h5>One-Stop Job Solution</h5>
                                <p>Apply to 100+ companies, access promos, and verify credentials seamlessly.</p>
                            </div>
                        </Col>

                        <Col lg="4" sm="6">
                            <div className="solution_card">
                                <img src={provider_access} />
                                <h5>Direct Job Provider Access</h5>
                                <p>Instant connections for job providers, real-time promo sharing.</p>
                            </div>
                        </Col>
                        <Col lg="4" sm="6">
                            <div className="solution_card">
                                <img src={ind_map} />
                                <h5>Cost-Effective PAN India Solution</h5>
                                <p>Cut costs, reduce dependency on multiple vendors, improved metrics.</p>
                            </div>
                        </Col>
                        <Col lg="4" sm="6">
                            <div className="solution_card">
                                <img src={promo_offer} />
                                <h5>Real-Time Promo Offers</h5>
                                <p>Stay updated on regular promotional offers to earn more.</p>
                            </div>
                        </Col>
                        <Col lg="4" sm="6">
                            <div className="solution_card">
                                <img src={digital_verify} />
                                <h5>Digital Verification through APIs</h5>
                                <p>Ensure authenticity and streamline verification processes.</p>
                            </div>
                        </Col>
                        <Col lg="4" sm="6">
                            <div className="solution_card">
                                <img src={targeted_marketing} />
                                <h5>Targeted Marketing</h5>
                                <p>Focus spending on last-mile delivery sector, streamline on-boarding.</p>
                            </div>
                        </Col>
                        <Col lg="auto" sm="6">
                            <div className="solution_card">
                                
                                <div className="d-flex align-items-center">
                                    <img src={nationwide_community} />
                                    <div>
                                        <h5>Nationwide Gig Community</h5>
                                        <p>Find GIG jobs across India, and also earn referral rewards.</p>
                                    </div>
                                </div>
                                
                            </div>
                        </Col>

                    </Row>
                    
                </Container>
            </section>
            
            <section className="secton_padding">
                <Container>
                    <div className=" hdng_title">
                        <h4 className="title_text text-center m-0">Why you should choose ProRider?</h4>
                    </div>
                    <div className="lmd_solutions_card">
                        <Row className="mt-3">
                            <Col sm="12" xl="10">
                                <div className="lmd_solutions_bdr">
                                    <Row>
                                        <Col sm="12" xl="7" className="">
                                            <div className="">
                                                <div className="lmd_solutions">
                                                    <img src={favicon} alt="image" />
                                                    <h5 className="m-0">One SuperApp for all LMD solutions</h5>
                                                </div>
                                                <ul className="lmd_solutions_list">
                                                    <li>A Marketplace for Delivery Riders & Last Mile Companies to seek and post dynamic job opportunities</li>
                                                    <li>Companies get an access to a pool of 2Mn+ PAN India Verified Riders (PAN, Aadhar, License & Bank Details etc.)</li>
                                                    <li>Riders get free access to multiple job opportunities as well as additional services like EV's, Short Term Loans, Rider Accessories, Bike Maintenance Discount Codes, etc.</li>
                                                </ul>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <img className="d-none d-xl-block comin_soon_app_ylw_bg" src={comin_soon_app_ylw_bg} />
                    </div>
                </Container>
            </section>

           
        
        {/* Coming Soon Start here */}
        <ComingSoonComponent />
        {/* Coming Soon End here */}
        </>
    )
}

export default AboutComponent;